import axios from 'axios'
import {
  apiCategoriesPath,
  apiProductSearchAutocompletePath,
  apiProductsPath,
  apiProductDetailsPath,
  apiProductsWith1sPath,
  apiOrderEmail,
  apiProductExportPath,
  apiProductsImportPath,
  apiProductStatistics,
  apiWithdrawalsPath,
  apiMappersPath,
} from 'config/apiPath'
import { apiBaseURL } from 'config/constants'
import { onlyClientOrGreater } from 'config/roles'
import { store } from 'storage'
import { setProductCategoriesAction } from 'storage/actions/productCategories.action'

import {
  setProductSearchAutocompleteAction,
  setProducts,
  setProduct,
  setIndividualPrice,
} from 'storage/actions/products.actions'

import { convertToCamelcase } from '../functions'

import instance from './axiosProvider'

export const getProductCategories = ({ page, limit }) => async (dispatch) => {
  try {
    const res = await instance.get(apiCategoriesPath, { params: { page, limit } })

    if (res) {
      const { data = {} } = res || {}
      const { success = false, categories = [], config = {} } = data
      const { page } = config
      if (success) {
        dispatch(setProductCategoriesAction(convertToCamelcase({
          categories,
          config: { ...config, page: page - 1 },
        })))
      }
    }
  } catch (err) {
    const { response = {} } = err || {}
    const { data = {} } = response
    const { message = {} } = data
    if (typeof message === 'string') {
      dispatch(setProductCategoriesAction(convertToCamelcase({ err: message })))
    }
    dispatch(setProductCategoriesAction(convertToCamelcase({ err: 'Failed' })))
  }
}

export const getSearchAutocomplete = search_text => async (dispatch) => {
  try {
    const res = await instance.get(apiProductSearchAutocompletePath, { params: { search_text } })

    if (res) {
      const { data = {} } = res || {}
      const { success = false, search_list } = data
      if (success) {
        dispatch(setProductSearchAutocompleteAction(search_list))
      }
    }
  } catch (err) {
    const { response = {} } = err || {}
    const { data = {} } = response
    const { message = {} } = data
    if (typeof message === 'string') {
      throw new Error(message)
    }
    throw new Error('Failed')
  }
}

const getIndivadualPrices = async (config, dispatch) => {
  const token = store.getState().loginData.token
  try {
    const { data = {} } = await axios.get(
      apiProductsWith1sPath,
      { params: config, baseURL: apiBaseURL, headers: { Authorization: token } },
    )
    const { products = [] } = data

    dispatch(setIndividualPrice(Object.fromEntries(products?.map(p => [
      p.id,
      { price_drop: p.price_drop, status: p.status },
    ]))))
  } catch (e) {
    dispatch(setIndividualPrice({}))
  }
}

export const getProductList = config => async (dispatch) => {
  try {
    const res = await instance.get(apiProductsPath, { params: config })

    if (res) {
      const { data = {} } = res || {}
      const {
        success = false,
        products = [],
        config = {},
        statistics = {},
        vendors,
        providers,
        categories,
      } = data

      const { page } = config
      let individualPriceLoading = false
      // if (onlyClientOrGreater()) {
      //   individualPriceLoading = true
      //   getIndivadualPrices(config, dispatch)
      // }

      if (success) {
        dispatch(setProducts({
          products,
          vendors,
          providers,
          categories,
          individualPriceLoading,
          statistics,
          config: {
            ...config,
            page: page - 1,
            searchText: config.search_text,
          },
        }))
      }
    }
  } catch (err) {
    const { response = {} } = err || {}
    const { data = {} } = response
    const { message = {} } = data
    if (typeof message === 'string') {
      dispatch(setProductCategoriesAction(convertToCamelcase({ err: message })))
    }
    dispatch(setProductCategoriesAction(convertToCamelcase({ err: 'Failed' })))
  }
}

export const getProductDetails = id => async (dispatch) => {
  try {
    const res = await instance.get(apiProductDetailsPath.replace(':id', id))
    if (res) {
      const { data = {} } = res || {}
      const {
        success = false,
        product = {},
        message,
      } = data
      if (success) {
        return dispatch(setProduct({
          error: null,
          data: product,
        }))
      }
      return dispatch(setProduct({
        error: message || 'Failed',
      }))
    }
  } catch (err) {
    const { response = {} } = err || {}
    const { data = {} } = response
    const { message = {} } = data
    if (typeof message === 'string') {
      dispatch(setProduct({ error: message }))
    }
    dispatch(setProduct({ error: 'Failed' }))
  }
}

export const uploadImportFile = async (data) => {
  try {
    const res = await instance.postForm(apiProductsImportPath, data)
    if (res.data.success) {
      return true
    }
  } catch (err) {
    throw new Error(err)
  }
}

export const saveMapper = async (data) => {
  try {
    const res = await instance.post(apiMappersPath, data)
    if (res.data.success) {
      return true
    }
  } catch (err) {
    throw new Error(err)
  }
}

export const getMapper = async () => {
  try {
    const res = await instance.get(apiMappersPath)
    return res.data;
  } catch (err) {
    throw new Error(err)
  }
}

export const downloadExportFile = async () => {
  try {
    return  await instance.get(apiProductExportPath, {responseType: 'blob'})
  } catch (err) {
    throw new Error(err)
  }
}

export const getStatistics = async (provider) => {
  try {
    const res = await instance.get(apiProductStatistics, {params: {provider}})
    return res.data;
  } catch (err) {
    throw new Error(err)
  }
}
