import React, {useEffect, useState} from 'react'
import Paper from '@material-ui/core/Paper'
import DataTable from 'mui-datatables'
import {getAllProviders} from "../../../services/api/provider.service";
import SearchInput from "../../parts/SearchInput";
import {feedDetailsPath, providerListPath, productsPath, providerDetailsPath} from "../../../config/routes";
import { useHistory } from "react-router-dom";
import { textLabels } from 'config/tableConfig/textLabels'
import {getAllFeeds} from "../../../services/api/feed.service";
import { Card, CardContent, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const FeedList = () => {

    const [feeds, setFeeds] = useState([]);
    const [search, setSearch] = useState(null);

    useEffect(() => {
        getAllFeeds().then((data) => {
            setFeeds(data);
        })
    }, []);

    const history = useHistory()

    const columns = [
        {name: 'id', label: 'Номер'},
        {name: 'name', label: 'Имя'},
        {name: 'updated_at', label: 'Дата обновления', options: { customBodyRender: value => new Date(value).toLocaleDateString('ru-RU') }},
    ]

    const options = {
        download: false,
        print: false,
        search: false,
        filter: false,
        viewColumns: false,
        selectableRowsHeader: false,
        selectableRows: 'none',
        pagination: true,
        textLabels,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 50, 100],
        onRowClick: (row) => {
            history.push(feedDetailsPath.replace(':id', row[0]))
        },
    }

    const onSearch = (searchQuery) => {
        setSearch(feeds.filter(feed => {
            var regExp = new RegExp(searchQuery, 'i')
            return feed.name.search(regExp) !== -1
                || feed.updated_at.search(regExp) !== -1;
        }))
    }

    return (
        <Paper>
            <Card fullWidth>
                <CardContent>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            color='textPrimary'
                            variant='caption'
                            style={{ fontSize: 24, textTransform: 'uppercase' }}
                        >
                            {"Мои XML выгрузки"}
                        </Typography>
                        <Typography
                            color='textSecondary'
                            variant='caption'
                            style={{ fontSize: 12 }}
                        >
                            {"Здесь отображается список сформированных вами XML-выгрузок с товарами."}<br />
                            {"XML-выгрузки можно сформировать в "}
                            <Link to={productsPath} style={{ color: '#DB4C19', textDecoration: 'underline' }}>
                                Каталоге
                            </Link>
                            {" или в разделе "}
                            <Link to={providerListPath} style={{ color: '#DB4C19', textDecoration: 'underline' }}>
                                Поставщики
                            </Link>
                            {"."}
                            <br />
                            {"Создавайте до 3-х XML-выгрузок для размещения товаров на разных торговых площадках."}
                        </Typography>
                    </div>
                </CardContent>
            </Card>
            <DataTable
                data={search ? search : feeds}
                options={options}
                columns={columns}
                title={<SearchInput tooltipsOpened={false} onSearch={onSearch} position='start' placeholder={'Поиск'}/>}
            />
        </Paper>
    )
}

export default FeedList
