import {
  apiCategoriesLevelPath, apiCategoriesPath, apiSubcategoriesPath, apiSubcategoryProductsPath,
} from 'config/apiPath'

import instance, { instanceWithotSnack } from './axiosProvider'


export const getCategoriesByLevel = async (level) => {
  try {
    const res = await instance.get(apiCategoriesLevelPath.replace(':level', level));
    return res.data;
  } catch (err) {
    throw new Error(err)
  }
}
export const getCategoriesWithSub = async (id) => {
  try {
    const res = await instance.get(apiSubcategoriesPath.replace(':id', id));
    return res.data;
  } catch (err) {
    throw new Error(err)
  }
}
export const getCategoryWithProducts = async (id) => {
  try {
    const res = await instance.get(apiSubcategoryProductsPath.replace(':id', id));
    return res.data;
  } catch (err) {
    throw new Error(err)
  }
}