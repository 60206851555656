export const styles = _theme => ({
  ProductPage: {
    padding: 20,
  },
  imageBlock: {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    '& img': {
      maxWidth: '100%',
      borderRadius: '3px',
    },
    marginBottom: 5,
  },
  detailItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 22,
  },
  countContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginTop: '20px',

  },
  inStock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  priceRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '2px solid #343232'
  },
  imageListItem: {    
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '3px',
    },
  },
  // paramsItem: {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   flexDirection: 'row',
  //   alignItems: 'center',
  //   marginTop: '15px',
  // },
})
