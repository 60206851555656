import React from 'react'
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import {
    newOrderPath,
    statisticPath,
    profilePath,
    productsPath,
    paymentLogPath,
    ordersPath,
    signInPath,
    productsCategoriesPath,
    productViewPath,
    preorderPath,
    productImportPath,
    userListPath,
    orderDetailsPath,
    catalogViewPath,
    catalogCategoryViewPath,
    catalogCategoryProductsViewPath,
    withdrawalsPath,
    withdrawalDetailsPath,
    providerListPath,
    providerDetailsPath,
    feedDetailsPath,
    feedListPath,
    productsProviderPath, productXmlImportPath, userViewPath,
} from 'config/routes'
import { ROOT_DOMAIN } from 'config/constants'
import {
    user,
    superAdmin,
    newUser,
    clientManager,
    client,
    admin,
    clientAccountant,
    purchasingManager,
    forAll,
    declineRoles, supplier,
} from 'config/roles'

import UserView from 'components/pages/UserView'
import Products from 'components/pages/products/Products'
import ProductCategories from 'components/pages/products/ProductCategories'
import NewOrder from 'components/pages/NewOrder/NewOrder'
import ProductView from 'components/pages/products/ProductView'
import Preorder from 'components/pages/Preorder'
import UserList from 'components/pages/UserList'
import OrderList from 'components/pages/OrderList'
import OrderDetails from 'components/pages/OrderDetails'

import PrivateRouter from 'components/parts/PrivateRoute'
import CatalogView from "components/pages/products/CatalogView";
import Import from "../pages/products/Import";
import DashboardView from "../pages/Dashboard/DashboardView";
import CatalogCategoryView from "../pages/products/CatalogCategoryView";
import CatalogCategoryProductsView from "../pages/products/CatalogCategoryProductsView";
import WithdrawalList from "../pages/WithdrawalList";
import WithdrawalDetails from "../pages/WithdrawalDetails";
import ProviderList from "../pages/ProviderList";
import ProviderDetails from "../pages/ProviderDetails";
import FeedDetails from "../pages/FeedDetails";
import FeedList from "../pages/FeedList";
import ProductList from "../pages/products/ProductList";
import XmlImport from "../pages/products/XmlImport";
import WorkingConditions from "../pages/Static/WorkingConditions";
import QuestionAnswer from "../pages/Static/QuestionAnswer";
import SellOnPromUa from "../pages/Static/SellOnPromUa";

const AuthRouter = () => (
  <Switch>
    <Redirect path={signInPath} to={profilePath} />
    <Redirect exact path={ROOT_DOMAIN} to={statisticPath} />
    <PrivateRouter path={profilePath} component={UserView} allowedRoles={declineRoles([newUser])} />
    <PrivateRouter
      path={statisticPath}
      exact
      component={DashboardView}
      allowedRoles={declineRoles([newUser])}
    />
    <PrivateRouter
      path={'/working-conditions'}
      exact
      component={WorkingConditions}
      allowedRoles={declineRoles([newUser, supplier])}
    />
    <PrivateRouter
      path={'/question-answer'}
      exact
      component={QuestionAnswer}
      allowedRoles={declineRoles([newUser, supplier])}
    />
    <PrivateRouter
      path={'/sell-on-prom-ua'}
      exact
      component={SellOnPromUa}
      allowedRoles={declineRoles([newUser, supplier])}
    />
    <PrivateRouter
      path={newOrderPath}
      exact
      component={NewOrder}
      allowedRoles={declineRoles([newUser])}
    />
    <PrivateRouter
      path={productsPath}
      exact
      component={ProductList}
      allowedRoles={declineRoles([newUser])}
    />
    <PrivateRouter
      path={productsProviderPath}
      exact
      component={ProductList}
      allowedRoles={declineRoles([newUser])}
    />
    <PrivateRouter
      path={productsCategoriesPath}
      component={ProductCategories}
      allowedRoles={declineRoles([newUser])}
    />
    <PrivateRouter
      path={orderDetailsPath}
      component={OrderDetails}
      allowedRoles={declineRoles([newUser])}
    />
    <PrivateRouter
      path={productViewPath}
      component={ProductView}
      allowedRoles={declineRoles([newUser])}
    />
    <PrivateRouter
      path={catalogCategoryProductsViewPath}
      component={CatalogCategoryProductsView}
      allowedRoles={declineRoles([newUser])}
    />
    <PrivateRouter
      path={catalogCategoryViewPath}
      component={CatalogCategoryView}
      allowedRoles={declineRoles([newUser])}
    />
    <PrivateRouter
      path={catalogViewPath}
      component={CatalogView}
      allowedRoles={declineRoles([newUser])}
    />
    <PrivateRouter
      path={productImportPath}
      component={Import}
      allowedRoles={[admin, superAdmin]}
    />
    <PrivateRouter
      path={productXmlImportPath}
      component={XmlImport}
      allowedRoles={[supplier]}
    />
    <PrivateRouter
      path={preorderPath}
      component={Preorder}
      allowedRoles={declineRoles([newUser])}
    />
    <PrivateRouter
      path={ordersPath}
      component={OrderList}
      allowedRoles={declineRoles([newUser])}
    />
    <PrivateRouter
      path={withdrawalDetailsPath}
      component={WithdrawalDetails}
      allowedRoles={declineRoles([newUser])}
    />
    <PrivateRouter
      path={withdrawalsPath}
      component={WithdrawalList}
      allowedRoles={declineRoles([newUser])}
    />
    <PrivateRouter
      path={userListPath}
      component={UserList}
      allowedRoles={[admin, superAdmin]}
    />
    <PrivateRouter
      path={userViewPath}
      component={UserView}
      allowedRoles={[admin, superAdmin]}
    />
    <PrivateRouter
        path={providerDetailsPath}
        component={ProviderDetails}
        allowedRoles={declineRoles([newUser])}
    />
    <PrivateRouter
      path={providerListPath}
      component={ProviderList}
      allowedRoles={declineRoles([newUser, supplier])}
    />
    <PrivateRouter
        path={feedDetailsPath}
        component={FeedDetails}
        allowedRoles={declineRoles([newUser, supplier])}
    />
    <PrivateRouter
      path={feedListPath}
      component={FeedList}
      allowedRoles={declineRoles([newUser, supplier])}
    />
    <Redirect path={ROOT_DOMAIN} to={profilePath} />
  </Switch>
)


export default AuthRouter
