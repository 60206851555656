import { store } from 'storage'

import { roles, userData } from 'storage/selectors/userData.selector'

export const newUser = 'newUser'
export const user = 'user'
export const client = 'client'
export const clientManager = 'сlientManager'
export const supplier = 'supplier'
export const clientAccountant = 'clientAccountant'
export const admin = 'admin'
export const superAdmin = 'superAdmin'

export const roleAccessLevel = {
  newUser: 0,
  user: 10,
  client: 20,
  сlientManager: 2,
  supplier: 25,
  clientAccountant: 2,
  admin: 30,
  superAdmin: 40,
}

export const forAll = [
  user,
  superAdmin,
  newUser,
  clientManager,
  client,
  admin,
  clientAccountant,
  supplier,
]

export const declineRoles = filter => {
  return forAll.filter(role => !filter.includes(role));
}

export const checkAccessByLevel = (level, equal = false) => {
  const userRoles = roles(store.getState())
  if (!userRoles || userRoles.length < 1) {
    return false
  }
  const maxAccessLevelRole = userRoles.sort((next, prev) => {
    if (roleAccessLevel[prev.name] > roleAccessLevel[next.name]) return 1
    return -1
  })[0].name
  if (equal) {
    return level === roleAccessLevel[maxAccessLevelRole]
  }
  return level <= roleAccessLevel[maxAccessLevelRole]
}

export const checkAlloved = ({ allowedRoles }) => {
  const userRoles = roles(store.getState())
  if (!allowedRoles) return true
  // if (allowedRoles.length <= 0) return true
  if (!userRoles) return false
  return userRoles.find(role => allowedRoles.includes(role.name))
}

export const checkNewUserProfileFilled = () => {
  return true;
}

export const onlyAdminOrGreater = () => checkAccessByLevel(roleAccessLevel.admin)

export const onlyClientOrGreater = () => checkAccessByLevel(roleAccessLevel.client)

export const onlySupplier = () => checkAccessByLevel(roleAccessLevel.supplier, true)
