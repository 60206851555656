import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'

import ProviderDetails from './ProviderDetails'
import { styles } from './ProviderDetails.styles'

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProviderDetails))
