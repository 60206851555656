import React, {useEffect, useState} from 'react'
import Paper from '@material-ui/core/Paper'
import DataTable from 'mui-datatables'
import {getAllProviders} from "../../../services/api/provider.service";
import SearchInput from "../../parts/SearchInput";
import {productsPath, productsProviderPath, providerDetailsPath} from "../../../config/routes";
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { textLabels } from 'config/tableConfig/textLabels'
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {CardContent, CardMedia, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {
    AddShoppingCartOutlined,
    EventAvailableOutlined,
    MonetizationOnOutlined, ReportOutlined,
    WorkOutlineOutlined
} from "@material-ui/icons";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import {useSelector} from "react-redux";
import {store} from "../../../storage";
import {REMOVE_FROM_CATALOG_CART, UPDATE_CATALOG_CART} from "../../../storage/constants/catalogCart.constants";
import { showSnack } from "../../../storage/actions/snack.actions";
import Tooltip from '@material-ui/core/Tooltip';

const ProviderList = () => {

    const [providers, setProviders] = useState([]);
    const [providersSearch, setProvidersSearch] = useState(null);

    useEffect(() => {
        getAllProviders().then((data) => {
            setProviders(data);
        })
    }, []);

    const history = useHistory()

    const catalogCart = useSelector((state) => state.catalogCart) || {};



    const renderDetails = function (provider) {
        const filterId = 'provider_' + provider.id;
        const isInCatalogCart = catalogCart.filters[filterId];

        const addToCatalogCart = () => {
            if (isInCatalogCart) {
                store.dispatch({
                    type: REMOVE_FROM_CATALOG_CART,
                    payload: {filterId},
                })
                return;
            }
            if (provider?.productCount < 1) {
                showSnack({
                    variant: 'error',
                    message: 'Нечего добавлять',
                });
                return;
            }
            try {
                store.dispatch({
                    type: UPDATE_CATALOG_CART,
                    payload: {filters: {[filterId]: {count: provider?.productCount, provider_id: provider?.provider}}},
                })
                showSnack({
                    variant: 'success',
                    message: `${provider?.productCount} товаров добавлено в выгрузку`,
                })
            } catch (e) {
                showSnack({
                    variant: 'error',
                    message: e.message,
                });
            }
        }

        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
        };

        return <div style={{'padding' : '10px'}}>
            <Grid key={provider.id} container>
                <Grid item md={3} style={{display: 'flex', background: '#262626', justifyContent: 'center', alignItems: "center", borderRadius: '4px', padding: '5px'}}>
                    <Card fullWidth>
                        <CardMedia
                            component="img"
                            // height="240px"
                            image={provider?.image}
                            alt="image"
                        />
                    </Card>
                </Grid>
                <Grid item md={6} style={{textAlign: 'center', color: 'white', background: '#262626', padding: '10px', borderRadius: '4px'}}>
                    <Typography variant="h6" component="div">
                        {provider?.name}
                        <span>{provider?.supplier_notification_sent ? <ReportOutlined color="error"/> : ''}</span>
                    </Typography>
                    <List>
                        <a href="" onClick={() => { history.push(productsProviderPath.replace(':provider', provider?.id)) }} style={{color: '#DB4C19'}}>
                            <ListItem disablePadding dense>
                                <ListItemIcon>
                                    <WorkOutlineOutlined style={{color: '#DB4C19'}}/>
                                </ListItemIcon>
                                <ListItemText primary="Товаров поставщика"/>
                                <span>{provider?.productCount}</span>
                            </ListItem></a>
                        <ListItem disablePadding dense>
                            <ListItemIcon>
                                <MonetizationOnOutlined style={{color: '#DB4C19'}}/>
                            </ListItemIcon>
                            <ListItemText primary="Профит"/>
                            <span>{_.round(provider?.profit).toFixed(0)}%</span>
                        </ListItem>
                        <ListItem disablePadding dense>
                            <ListItemIcon>
                                <AddShoppingCartOutlined style={{color: '#DB4C19'}}/>
                            </ListItemIcon>
                            <ListItemText primary="Всего заказов"/>
                            <span>{provider?.orderCount}</span>
                        </ListItem>
                        <ListItem disablePadding dense>
                            <ListItemIcon>
                                <EventAvailableOutlined style={{color: '#DB4C19'}}/>
                            </ListItemIcon>
                            <ListItemText primary="Прайс обновлен"/>
                            <span>{formatDate(provider?.last_update_date)}</span>
                        </ListItem>
                    </List>
                    <Tooltip
                        title={isInCatalogCart
                            ? `Удаляйте товары поставщика ${provider?.name} из выгрузки`
                            : `Добавляйте товары поставщика ${provider?.name} в выгрузку`}
                        arrow
                        placement="right"
                    >
                        
                    <Button onClick={addToCatalogCart} variant='contained' color='primary' >
                        {isInCatalogCart ? 'Удалить из выгрузки' : 'Добавить в выгрузку'}
                    </Button>
                    </Tooltip>
                </Grid>
            </Grid>
        </div>
    }

    return (
            <>
                <Grid md={9} container style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', padding: '10px'}}>
                    <Typography
                        color='textPrimary'
                        variant='caption'
                        style={{ fontSize: 24, textTransform: 'uppercase' }}
                    >
                        {"Поставщики"}
                    </Typography>
                    <Typography
                        color='textSecondary'
                        variant='caption'
                        style={{ fontSize: 12 }}
                    >
                        {"Здесь отображается список всех поставщиков, товары которых доступны на платформе."}<br />
                        {"Вы можете добавить в выгрузку товары конкретных поставщиков или выбрать товары в общем " }
                        <Link
                            to={productsPath}
                            style={{ fontSize: 12, color: '#DB4C19', display: 'inline-block'}}
                        >Каталоге</Link>
                        {", используя фильтры."}
                    </Typography>
                </Grid>
            {providers.map(provider => renderDetails(provider))}
        </>
    )
}

export default ProviderList
