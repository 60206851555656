import React, {Component, useEffect, useState} from 'react'
import {Box, Link, styled} from "@material-ui/core";
import {onlyAdminOrGreater} from "../../../../config/roles";
import Button from "@material-ui/core/Button";
import {updateOrder} from "../../../../services/api/order.service";
import {showSnack} from "../../../../storage/actions/snack.actions";
import {CloudUpload} from "@material-ui/icons";
import Divider from "@material-ui/core/Divider";
import {apiBaseURL} from "../../../../config/constants";
import {apiProductExportPath} from "../../../../config/apiPath";
import {downloadExportFile, uploadImportFile} from "../../../../services/api/products.service";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Import = (props) => {

    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const onSubmit = (e) => {
        e.preventDefault()
        if (!file) {
            showSnack({
                variant: 'error',
                message: 'Выберите файл',
            });
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        uploadImportFile(formData).then((success) => {
            if (success) {
                showSnack({
                    variant: 'success',
                    message: `Файл успешно загружен!`,
                });
            }
        })
    }

    const downloadFile = (e) => {
        e.preventDefault()

        downloadExportFile().then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'products.xlsx');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
    }


    return (
        <>
            <Box display={onlyAdminOrGreater() ? "flex" : "none"} sx={{ m: 2 }}>
                <Link href="#" onClick={downloadFile} >Скачать Excel файл</Link>
            </Box>
            <Divider />
            <form onSubmit={onSubmit}>
                <Box display={onlyAdminOrGreater() ? "flex" : "none"} sx={{ m: 2 }}>
                    <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                        Выбрать файл
                        <VisuallyHiddenInput type="file" name="file" onChange={handleFileChange} />
                    </Button>
                    <span style={{ padding: 6 }}>{file?.name}</span>
                </Box>
                <Box display={onlyAdminOrGreater() ? "flex" : "none"} sx={{ m: 2 }}>
                    <Button variant='contained' type='submit'>Загрузить</Button>
                </Box>
            </form>
        </>
    );
}

export default Import
