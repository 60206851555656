import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import { getProductDetails } from 'services/api/products.service'
import { productInfo } from 'storage/selectors/products.selector'

import Import from './Import'
import { styles } from './ImportStyles'

const mapStateToProps = state => ({
  // product: productInfo(state),
})

const mapDispatchToProps = dispatch => ({
  // getData: id => dispatch(getProductDetails(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Import))
