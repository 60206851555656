import { connect } from 'react-redux'
import withWidth from '@material-ui/core/withWidth'

import { getCatalogCart } from 'storage/selectors/catalogCart.selector'

import CatalogCartIndicator from './CatalogCartIndicator'

const mapStateToProps = state => ({
  catalogCart: getCatalogCart(state),
})

export default withWidth()(connect(mapStateToProps)(CatalogCartIndicator))
