import React, {Component, useEffect, useState} from 'react'
import {Box, CardActions, CardMedia, Link, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {getCategoriesByLevel, getCategoriesWithSub} from "../../../../services/api/category.service";
import _ from "lodash";
import {catalogCategoryProductsViewPath, catalogCategoryViewPath} from "../../../../config/routes";
import {useHistory} from "react-router-dom";
import WorkOutlineOutlined from "@material-ui/icons/WorkOutlineOutlined";
import BrandingWatermarkOutlined from "@material-ui/icons/BrandingWatermarkOutlined";
import PersonOutlined from "@material-ui/icons/PersonOutlined";
import CategoryCard from "../CategoryCard";
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';

const CatalogView = (props) => {
    const categoryId = props?.match?.params?.id
    const [subCategories, setSubCategories] = useState([]);
    const history = useHistory();
    useEffect(() => {
        getCategoriesWithSub(categoryId).then((data) => {
            setSubCategories(data.subCategories);
        }).catch((err) => {
            console.log(err)
        })
    }, [categoryId]);

    return (
        <>
            {
                subCategories.map(category => <Grid key={category.id} container spacing={2} >
                    <Grid item container md={12} >
                        <Grid item md={12} >
                            <Button onClick={() => { history.push(catalogCategoryViewPath.replace(':id', category.id)); }}>
                                <Card style={{ display: 'flex', width: '100%' }}>
                                    <div>
                                        <CardMedia
                                            component="img"
                                            style={{ maxHeight: 120, width: 'auto' }}
                                            image={category.image}
                                            alt="image"
                                        />
                                    </div>
                                    <CardContent component="div" style={{ margin: 'auto' }}>
                                        <Typography variant="h6" style={{ whiteSpace: 'normal', marginBottom: '10px' }}>
                                            {_.truncate(category.name)}
                                        </Typography>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Typography
                                                color='textSecondary'
                                                variant='caption'
                                                style={{ whiteSpace: 'normal', marginRight: '5px' }}>
                                                    Посмотреть все
                                            </Typography>
                                            <ArrowForwardIosOutlinedIcon style={{ fontSize: '14px' }} />
                                        </div>
                                    </CardContent>
                                </Card>
                            </Button>
                        </Grid>
                    </Grid>
                    {category.children.map(childCategory => <CategoryCard key={childCategory.id} category={childCategory} />)}

                    <Grid item md={12} align={'center'}>
                        <Divider />
                        <br/>
                    </Grid>
                </Grid>
                )
            }

        </>
    );
}

export default CatalogView
