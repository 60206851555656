import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import { setRolesToUser } from 'services/api/role.service'
import {MenuItem, Select} from "@material-ui/core";

const FormDialog = ({
  open,
  userRoles,
  close,
  userId,
  userData,
  onSuccess,
}) => {
  console.log(userRoles)
  const [newRoles, setNewRoles] = useState(userRoles.map(userRole => userRole.id) || [])

  const handleClose = () => {
    close()
  }

  const handleSave = async () => {
    if (await setRolesToUser({ userId, userRoleIds: newRoles })) {
      handleClose()
      onSuccess()
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Присвоить Роль</DialogTitle>
        <DialogContent>
          <DialogContentText>
            { 'Роли' }
          </DialogContentText>
          <Select
              multiple={true}
              fullWidth
              value={newRoles}
              onChange={(event) => {
                setNewRoles(event.target.value)
              }}
          >
            {userData.roles.map(role => (
                <MenuItem key={role.id} value={role.id}>
                  {role.display_name}
                </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Отмена
          </Button>
          <Button onClick={handleSave} color='primary'>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

FormDialog.defaultProps = {
  open: false,
  userRoles: [],
  userId: null,
}

FormDialog.propTypes = {
  open: PropTypes.bool,
  userRoles: PropTypes.array,
  close: PropTypes.func.isRequired,
  userId: PropTypes.number,
  onSuccess: PropTypes.func.isRequired,
}


export default function wrapper(props) {
  const {
    open,
  } = props
  return open ? <FormDialog {...props} /> : <></>
}

wrapper.defaultProps = {
  open: false,
}

wrapper.propTypes = {
  open: PropTypes.bool,
}
