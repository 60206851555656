import React, {Component, useEffect, useState} from 'react'
import {Box, Container, Paper, List, ListItem, ListItemIcon, Link as MuiLink, styled} from "@material-ui/core";
import {onlyAdminOrGreater} from "../../../../config/roles";
import Button from "@material-ui/core/Button";
import {updateOrder} from "../../../../services/api/order.service";
import {showSnack} from "../../../../storage/actions/snack.actions";
import {CloudUpload} from "@material-ui/icons";
import Divider from "@material-ui/core/Divider";
import {apiBaseURL} from "../../../../config/constants";
import { apiProductExportPath } from "../../../../config/apiPath";
import {productsPath} from "config/routes";
import {
    downloadExportFile, getMapper,
    saveMapper,
    uploadImportFile
} from "../../../../services/api/products.service";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import TextField from "@material-ui/core/TextField";
import MonetizationOnOutlined from '@material-ui/icons/MonetizationOnOutlined';
import LocalOfferOutlined from '@material-ui/icons/LocalOfferOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CheckBoxOutlined from '@material-ui/icons/CheckBoxOutlined';
import ReportProblemOutlined from '@material-ui/icons/ReportProblemOutlined';
import { Link } from 'react-router-dom';

const XmlImport = ({classes}) => {
    const [formData, setFormData] = useState({})
    const [mapper, setMapper] = useState({})
    // debugger;

    useEffect(() => {
        getMapper().then((data) => {
            setMapper(data);
        })
    }, []);

    const onSubmit = (e) => {
        e.preventDefault()

        saveMapper(formData).then((success) => {
            if (success) {
                showSnack({
                    variant: 'success',
                    message: `Файл успешно сохранен!`,
                });
            }
        })
    }

    return (
        
            <Paper style={{ padding: '16px 6px'}}>
            <Container style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                paragraph
                                color='textPrimary'
                                style={{fontSize: 24, textTransform: 'uppercase'}}
                            >
                                {"Загрузка товаров в каталог"}
                            </Typography>
                            <Typography
                                color='textPrimary'
                                paragraph
                                style={{fontSize: 14}}
                            >
                                {"Загружайте товары на платформу с помощью XML-файла, чтобы тысячи дропшипперов продавали ваши товары."}<br/>
                                {"После валидации XML-файла и успешной загрузки ваши товары отобразятся в общем "}
                                <Link
                                    to={productsPath}
                                    style={{fontSize: 14, color: '#DB4C19', display: 'inline-block'}}
                                >
                                    Каталоге
                                </Link>
                            </Typography>
                            <Divider/>
                            <form onSubmit={onSubmit}>
                                <Box display={"flex"} style={{marginBottom: '10px'}}>
                                    <div style={{minWidth: '400px'}}>
                                        <Typography
                                            color='textSecondary'
                                            style={{ whiteSpace: 'nowrap', fontSize: '14px'}}>
                                                Ссылка на XML-файл:
                                        </Typography>
                                        <TextField
                                            fullWidth name="xml_file"
                                            variant='outlined'
                                            value={formData?.xml_file !== undefined ? formData?.xml_file : mapper?.xml_file}
                                            onChange={event => setFormData({...formData, xml_file: event.target.value})}
                                            disabled={!(!mapper?.xml_file || mapper?.importError)}
                                        />
                                    </div>
                                </Box>
                                <Box display={"flex"} style={{marginBottom: '20px'}}>
                                    <div style={{minWidth: '400px'}}>
                                        <Typography
                                            color='textSecondary'
                                            style={{ fontSize: '14px' }}>
                                                Укажите % скидки от РРЦ для дропшипперов:
                                        </Typography>
                                        <TextField
                                            variant='outlined'
                                            fullWidth name="supplier_price_discount"
                                            value={formData?.supplier_price_discount !== undefined ? formData?.supplier_price_discount : mapper?.supplier_price_discount}
                                            onChange={event => setFormData({...formData, supplier_price_discount: event.target.value})}
                                            disabled={!(!mapper?.xml_file || mapper?.importError)}
                                        />
                                    </div>
                                </Box>
                                <Box display="flex" alignItems="center" mb={2} mt={2}>
                                    <MonetizationOnOutlined color="primary" style={{ marginRight: '6px' }} />
                                    <Typography variant='body1' color='primary' >
                                        Комиссия платформы составляет 5% от суммы заказа
                                    </Typography>
                                </Box>
                                <Box display={"flex"} mb={2} mt={2}>
                                    <div style={{minWidth: '400px'}}>
                                    <Typography color='textPrimary'
                                        paragraph
                                        variant="body2">
                                            Например, вы укажете скидку 25%. Это означает, для платформы ваша скидка составляет 25%, а для дропшипперов цена на ваши товары отображается со скидкой 20% от РРЦ.
                                    </Typography>
                                    </div>
                                </Box>
                    
                                <Box display="flex" alignItems="center" mb={2} mt={2}>
                                    <LocalOfferOutlined color="primary" style={{ marginRight: '6px' }} />
                                    <Typography variant='body1' color='primary'>
                                        Минимальная скидка для дропшипперов -15%
                                    </Typography>
                                </Box>
                
                                <Box display={"flex"} mb={2} mt={2}>
                                    <div style={{minWidth: '400px'}}>
                                    <Typography color='textPrimary'
                                        paragraph
                                        variant="body2">
                                            По нашим данным, товары, доход от которых составляет менее 15%, не пользуются
                                            спросом у дропшипперов, так как заработок не покрывает затраты на рекламу и прочие
                                            расходы. Также скидка 15% - это своего рода психологический барьер,
                                            ниже которого дропшипперы даже не рассматривают товары к продаже.
                                        </Typography>
                                    </div>
                                </Box>
                                <Box display={"flex"} mb={2} mt={2}>
                                    <Button
                                        variant='contained'
                                        type='submit'
                                        color="primary"
                                        disabled={!(formData?.xml_file || (formData?.xml_file && mapper?.importError))}
                                    >
                                        Загрузить
                                    </Button>
                                </Box>
                            </form>
                            <Divider/>
                            {mapper.statistic &&
                                <div style={{ border: '2px solid #2AC77B', padding: '10px', borderRadius: '4px' }}>
                                    <Box display="flex" alignItems="center" mb={2} mt={2}>
                                        <CheckBoxOutlined style={{ marginRight: '6px', color: '#2AC77B' }} />
                                        <Typography variant='h6' style={{ color: '#2AC77B' }}>
                                            Ваши товары успешно загружены!
                                        </Typography>
                                    </Box>
                                    <Typography variant="body1">
                                        Результат импорта:
                                    </Typography>
                                        <ul style={{ lineHeight: '2em', marginTop: '6px' }}>
                                            <li>
                                                <Typography variant="body2">
                                                    Дата и время: {mapper.statistic.last_run}
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant="body2">
                                                    Создано товаров: {mapper.statistic.created}
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant="body2">
                                                    Обновлено товаров: {mapper.statistic.updated}
                                                </Typography>
                                            </li>
                                        </ul>
                                </div>
                            }
                            {mapper.importError &&
                                <div style={{ border: '2px solid #e53935', padding: '10px', borderRadius: '4px' }}>
                                    <Box display="flex" alignItems="center" mb={2} mt={2}>
                                        <ReportProblemOutlined style={{ marginRight: '6px', color: '#e53935' }} />
                                        <Typography variant='h6' style={{ color: '#e53935' }}>
                                            Что-то пошло не так с импортом:(
                                        </Typography>
                                    </Box>
                                    <Typography variant="body1">
                                        Результат импорта:
                                    </Typography>
                                        <ul style={{ lineHeight: '2em', marginTop: '6px' }}>
                                            <li>
                                                <Typography variant="body2">
                                                    Товары не загружены
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant="body2">
                                                    Не волнуйтесь, наши специалисты импортируют ваши товары. По результату вы получите уведомление
                                                </Typography>
                                            </li>
                                        </ul>
                                </div>
                }
                <Typography variant="h6" style={{paddingTop: '20px'}}>
                    Как платформа импортирует товары из вашего XML-файла?
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small' />
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            После того как вы добавили XML-файл для импорта, менеджеры платформы проверят ваши товары
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Затем админы платформы разместят товары в соответствующих категориях и активируют их в каталоге платформы
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            После чего товары будут доступны всем дропшипперам для импорта к себе на сайты и для заказов
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Платформа загружает информацию из XML-файла поставщика каждый час
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            После каждого импорта вы увидите результат загрузки/обновления товаров из файла
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Товары, которые были импортированы на платформу из XML-файла ранее, а потом были удалены из XML-файла, будут становиться неактивными в каталоге платформы
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            При необходимости изменить XML-файл - напишите нам в 
                            <MuiLink href="https://t.me/ceodropshippingua" target="_blank" rel="noopener noreferrer" style={{ color: '#2792E0', textDecoration: 'none', fontSize: '14px' }}>
                                    {' телеграм '}
                                    </MuiLink>
                        </Typography>
                    </ListItem>
                </List>
                <Typography variant="h6" paragraph style={{paddingTop: '20px'}}>
                    Требования к частоте обновления информации в XML-файле
                </Typography>
                <Typography variant="body2" fontSize="14px">
                    Для обеспечения актуальности информации о ваших товарах на платформе, пожалуйста, соблюдайте следующие требования к частоте обновления XML-файла:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small' />
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            XML-файл должен обновляться не реже, чем 1 раз в час!
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            XML-файл должен иметь статичный адрес ссылки, по которому он доступен
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Файл всегда должен содержать актуальные цены
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Файл всегда должен содержать актуальное наличие
                        </Typography>
                    </ListItem>
                </List>
                <Typography variant="h6" style={{paddingTop: '20px'}}>
                    ❗❗❗ Почему это так важно? 
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small' />
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Это необходимо для того, чтобы дропшипперы, которые продают ваши товары, всегда получали актуальное наличие и цены на товары
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Это снижает вероятность заказов на товары, которых нет в наличии
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Это исключает оплату товаров, которые закончились или подорожали
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Это увеличивает доверие к вам как к поставщику, и снижает вероятность конфликтов с дропшипперами и покупателями, связанных с возвратами средств и отменой заказов
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Если ваш товар в XML-файле отображается как «В наличии», но при оформлении заказа товар фактически отсутствует, то такой поставщик будет понижаться в рейтинге на платформе
                        </Typography>
                    </ListItem>
                </List>
                </Container>
            </Paper>
       
    );
}

export default XmlImport
