import React, { memo } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import DataTable from 'mui-datatables'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { productViewPath } from 'config/routes'
import { formatPrice } from 'components/parts/FormParts/PriceFormatter';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { textLabels } from 'config/tableConfig/textLabels'



const Table = memo(({ products }) => {
  const history = useHistory();

  const options = {
    download: false,
    print: false,
    search: false,
    filter: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRows: 'none',
    pagination: false,
    textLabels,
    onRowClick: (rowData) => {
      const product = products.find(product => product.id === rowData[0]);
      if (product) {
        history.push(productViewPath.replace(':id', product.id));
      }
    },
  };

  const columns = [
    { name: 'id', label: '', options: { display: false } },
    { name: 'vendor_code', label: 'Артикул' },
    {
      name: 'image',
      label: ' ',
      options: {
        customBodyRender: value => <img alt='Картинка' height='50' src={value} />,
      },
    },
    { name: 'name', label: 'Название' },
    { name: 'count', label: 'Количество' },
    
    {
      name: 'price',
      label: 'Дроп-цена',
      options: {
        customBodyRender: value => (
      <>
        {formatPrice(Number(value))} ₴
      </>
    ),
      },
    },
    {
      name: 'total',
      label: 'Сумма',
      options: {
        customBodyRender: value => (
      <>
        {formatPrice(Number(value))} ₴
      </>
    ),
      },
    },
  ];

  const productsWithTotal = products.map(
    product => ({ ...product, total: (product.price * product.count)}),
  );

  return (
    <Paper>
      {/* <MuiThemeProvider theme={themePaperWithoutShadow}> */}
        <DataTable
          columns={columns}
          data={productsWithTotal}
          title='Список товаров в заказе'
          options={options}
      />
      {/* </MuiThemeProvider> */}
    </Paper>
  );
});

Table.defaultProps = {
  products: [],
};

Table.propTypes = {
  products: PropTypes.array,
};

export default Table;
