import React, {useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import {Button, Dialog, DialogActions, FilledInput, Input, TextField} from '@material-ui/core'

import { getHelperText } from 'components/parts/ReactiveForm/Helpers'
import {calculateCartTotal} from "../../../../services/cart/cartHelpers";
import {getCart} from "../../../../storage/selectors/cart.selector";
import {store} from "../../../../storage";
import {useSelector} from "react-redux";
import {de} from "date-fns/locale";
import {FormContext} from "../../../parts/ReactiveForm/formContext";
import debounce from "lodash/debounce";
import {round} from "lodash";

const defaultMeta = {
  variant: 'outlined',
  fullWidth: true,
  type: 'text',
}

const PaymentAmountInput = ({
  value,
  errors,
  touched,
  invalid,
  handlers,
  meta,
  InputProps,
}) => {
  const {
    label,
    placeholder,
    variant,
    fullWidth,
    type,
    disabled,
    errorMessages,
  } = meta;
  const { controls } = useContext(FormContext);
  const [promPaymentOpen, setPromPaymentOpen] = useState(false);
  const [isDropBalancePayment, setIsDropBalancePayment] = useState(false)
  const [dropAmount, setDropAmount] = useState(0)
  // const [value, setValue] = useState(0)
  const cartTotal = calculateCartTotal(getCart(store.getState()))

  const control = controls['paymentType']
  const isPrepayment = control.value === 1;
  const isCashOnDelivery = control.value === 2;
  const isPromPayment = control.value === 3;
  var userData = useSelector((state) => state.userData) || {}
  const throttledChanges = debounce((value, setDropAmount) => {
    setDropAmount(value)
  }, 500)
  if (!handlers.onChange) {
    handlers.onChange = (e)=>{
      const value = e.currentTarget.value.replace(/\s+/g, ' ');
        e.currentTarget.value = value;
        if (!userData.dropBalance) {
          throttledChanges(0, setDropAmount);
          return;
        }
        if (value >= cartTotal) {
          controls['dropPaymentAmount'].setValue(0)
          throttledChanges(0, setDropAmount);
          return;
        }
        const totalDiff = cartTotal - value;
        const dropAmount = value < cartTotal && totalDiff < userData.dropBalance ? round(cartTotal - value, 2) : userData.dropBalance;
      throttledChanges(dropAmount, setDropAmount);
      // setValue(value);
    }
  }
  useEffect(() => {
    var dropAmount = 0;
    if (isPrepayment && value) {
      const totalDiff = cartTotal - value;
      dropAmount = value < cartTotal && totalDiff < userData.dropBalance ? round(cartTotal - value, 2) : userData.dropBalance;
    }
    if (isCashOnDelivery || isPromPayment) {
      dropAmount = cartTotal > userData.dropBalance ? userData.dropBalance : cartTotal;
    }
    controls['dropPaymentAmount'].setValue(isDropBalancePayment ? dropAmount : 0)
    throttledChanges(dropAmount, setDropAmount);
  }, [isPrepayment, isCashOnDelivery, isPromPayment])





  return (
[
  !isPrepayment
      ?
      <div>
        <Dialog
            onClose={e => {setPromPaymentOpen(false)}}
            aria-labelledby='edit-user-data'
            open={promPaymentOpen}

        >
          <div style={{padding: '20px'}}>
            Заказы с пром оплатой оформляются также как и обычные заказы на платформе, мы отправим заказ по своей ТТН, вам останется только внести № ТТН в заказ в кабинете Пром.юа чтобы деньги поступил вам на счет после получения заказа покупателем
          </div>
          <DialogActions>
            <Button
                variant='contained'
                color='secondary'
                onClick={e => {setPromPaymentOpen(false)}}
            >
              закрыть
            </Button>
          </DialogActions>
        </Dialog>
        <div>Сумма: {calculateCartTotal(getCart(store.getState())).toLocaleString()}</div>
        {(isPromPayment) &&
            <a href="#" style={{fontSize: '0.75rem'}} onClick={e => setPromPaymentOpen(true)}
               className="MuiFormLabel-root">Детальнее про пром оплату</a>}
      </div>
      : <TextField
          disabled={disabled}
          label={label}
          {...handlers}
          defaultValue={value}
          error={touched && invalid}
          variant={variant || 'outlined'}
          fullWidth={fullWidth}
          margin='normal'
          type={type || 'text'}
          placeholder={placeholder || label}
          InputProps={InputProps}
          helperText={
              touched && invalid && getHelperText(errors, errorMessages)
          }
      />,
  isDropBalancePayment && dropAmount ? <p>Будет списано {dropAmount} с дроп баланса</p> : null,
  dropAmount > 0  ?
      <div style={{fontSize: '0.75rem'}}>
        <div>Доступно на дроп балансе {userData.dropBalance}</div>
        <label htmlFor="drop_balance_payment">Списать {dropAmount} грн с дроп баланса</label>
        <input id="drop_balance_payment" type="checkbox" onChange={() => {controls['dropPaymentAmount'].setValue(!isDropBalancePayment ? dropAmount : 0); setIsDropBalancePayment(!isDropBalancePayment)}}/>
      </div>
      : null
]
  )
}



PaymentAmountInput.defaultProps = {
  value: '',
  errors: {},
  touched: false,
  invalid: false,
  handlers: {},
  meta: {},
  InputProps: {},
}

PaymentAmountInput.propTypes = {
  value: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.bool,
  invalid: PropTypes.bool,
  handlers: PropTypes.object,
  meta: PropTypes.object,
  InputProps: PropTypes.object,
}

export default PaymentAmountInput
