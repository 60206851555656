import React, {useEffect, useState} from 'react'
import { apiBaseURL } from "../../../config/constants";
import Paper from '@material-ui/core/Paper'
import { Accordion, AccordionSummary, AccordionDetails, Card, CardContent, Typography, Container, List, ListItem, Box, ListItemIcon, Link } from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TelegramIcon from '@material-ui/icons/Telegram';

const imagesUrl = apiBaseURL.slice(0, -3) + 'images'

const QuestionAnswer = (props) => {
    return (
        <Paper style={{ padding: '16px 6px'}}>
            <Container style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                    variant='h6'
                    paragraph
                    align='center'
                    style={{ fontSize:  20 }}
                >
                    Часто задаваемые вопросы (FAQ) для дропшипперов
                </Typography>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                            Что такое дропшиппинг?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckOutlinedIcon color="primary" fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" fontSize="14px">
                                    Дропшиппинг – это модель бизнеса, при которой вы продаёте товары, не имея их на складе. <br />Вы размещаете товары на своем сайте, а после того как клиент заказал товар у вас, вы оформляете заказы на платформе, и мы отправляем товар непосредственно вашему клиенту новой почтой.
                                </Typography>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
                
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                            Как начать работать на вашей платформе?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckOutlinedIcon color="primary" fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" fontSize="14px">
                                    Чтобы начать работать на нашей платформе, зарегистрируйтесь в качестве дропшиппера. <br />После регистрации вам будет доступен каталог товаров и поставщиков, а также возможность делать заказы, видеть свой дроп-баланс и запрашивать выплаты вашего зароботка.
                                </Typography>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                            Сколько стоит доступ к платформе?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckOutlinedIcon color="primary" fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" fontSize="14px">
                                    Для дропшипперов платформа бесплатна.
                                </Typography>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                            Как импортировать товары на свой сайт или маркетплейс?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                        <ol style={{ lineHeight: '2.5em', marginTop: '8px' }}>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Выберите интересующие вас группы товаров в каталоге
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Добавьте товары в выгрузку
                                    </Typography>
                                </li>
                                <img
                                src={`${imagesUrl}/2669eadc-645d-4384-961d-b825498caa31.png`}
                                alt="image-20240531-153549.png"
                                style={{ display: 'block', width: '80%', margin: '16px auto' }}
                                />
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Создайте XML-выгрузку и подходящее название выгрузки (можно создавать до 3-х выгрузок товаров)
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Скопируйте ссылку на выгрузку, открывать ссылку в браузере не нужно 😉
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                    {"Вставьте ссылку на ваш сайт, например на сайт "}
                                    <a href="https://prom.ua/reg_fr/154185" target="_blank" rel="noopener noreferrer" style={{ color: '#DB4C19', textDecoration: 'none' }}>
                                        prom.ua
                                    </a>
                                    {", и нажмите Импорт товаров"}
                                </Typography>
                                </li>
                                <img
                                src={`${imagesUrl}/a90a219c-b7e8-4ab2-b91d-2504a34c6658.png`}
                                alt="image-20240531-154055.png"
                                style={{ display: 'block', width: '80%', margin: '16px auto' }}
                                />
                            </ol>
                            </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                            Где продавать товары?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <Typography variant='body2' paragraph>
                                Товары с платформы вы можете продавать на 3-х основных площадках в интернете:
                            </Typography>
                            <Typography variant="body2" fontSize="14px" style={{ fontWeight: 500 }}>
                                1. Сайты или маркетплейсы
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                        <a href="https://prom.ua/reg_fr/154185" target="_blank" rel="noopener noreferrer" style={{ color: '#DB4C19', textDecoration: 'none' }}>
                                            Prom.ua
                                        </a>
                                        {" - крупнейший маркетплейс Украины (регистрируйтесь и получите 500 грн на счет)"}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                        <a href="https://seller.rozetka.com.ua/" target="_blank" rel="noopener noreferrer" style={{ color: '#DB4C19', textDecoration: 'none' }}>
                                            Rozetka Marketplace
                                        </a>
                                        {" - еще один очень крупный маркетплейс"}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                        <a href="https://www.similarweb.com/ru/website/allo.ua/" target="_blank" rel="noopener noreferrer" style={{ color: '#DB4C19', textDecoration: 'none' }}>
                                            Allo.ua
                                        </a>
                                        {" - маркетплейс от интернет-магазина Алло"}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                        <a href="https://epicentrk.ua/ua/seller/" target="_blank" rel="noopener noreferrer" style={{ color: '#DB4C19', textDecoration: 'none' }}>
                                            Эпицентр
                                        </a>
                                        {" - маркетплейс от строительного гипермаркета"}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                        <a href="http://Hotline.ua" target="_blank" rel="noopener noreferrer" style={{ color: '#DB4C19', textDecoration: 'none' }}>
                                            Hotline.ua
                                        </a>
                                        {" - прайс-агрегатор"}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                        <a href="http://Price.ua" target="_blank" rel="noopener noreferrer" style={{ color: '#DB4C19', textDecoration: 'none' }}>
                                            Price.ua
                                        </a>
                                        {" - еще один прайс-агрегатор"}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                <ListItemIcon>
                                    <CheckOutlinedIcon color="primary" fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" fontSize="14px">
                                    Свой собственный сайт - если он у вас есть 🙂
                                </Typography>
                                </ListItem>                                
                            </List>

                            <Typography variant="body2" fontSize="14px" style={{ fontWeight: 500 }}>
                                2. Доски объявлений
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                        <a href="http://OLX.ua" target="_blank" rel="noopener noreferrer" style={{ color: '#DB4C19', textDecoration: 'none' }}>
                                            OLX.ua
                                        </a>
                                        {" - крупнейшая доска объявлений в Украине"}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                        <a href="http://Shafa.ua" target="_blank" rel="noopener noreferrer" style={{ color: '#DB4C19', textDecoration: 'none' }}>
                                            Shafa.ua
                                        </a>
                                        {" - доска объявлений с одеждой и товарами для дома"}
                                    </Typography>
                                </ListItem>                               
                            </List>

                            <Typography variant="body2" fontSize="14px" style={{ fontWeight: 500 }}>
                                3. Соцсети
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                    Тик-Ток
                                </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                    Инста
                                </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                    ФБ и другие
                                </Typography>
                                </ListItem>
                            </List>
                            <Typography variant="body1" paragraph style={{ fontWeight: 500 }}>
                                Мы рекомендуем начинать продажи с сайта на Проме.
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                            Что такое Рекомендованная Розничная Цена (РРЦ)?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckOutlinedIcon color="primary" fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" fontSize="14px">
                                    Рекомендованная Розничная Цена (РРЦ) – это цена, которую производитель или поставщик рекомендует устанавливать для продажи товара конечным потребителям в интернете.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckOutlinedIcon color="primary" fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" fontSize="14px">
                                    РРЦ диктует всем продавцам идентичную цену на товар, которую видит покупатель. <br/>Это служит для исключения демпинга (снижения цены продажи на товары) интернет продавцами, чтобы все могли заработать с продажи товаров достойный процент наценки.
                                </Typography>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                            Что такое Дроп-Цена?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckOutlinedIcon color="primary" fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" fontSize="14px">
                                    Дроп-Цена - это оптовая цена, по который вы “покупаете” товар на платформе, который потом продаете клиентам по РРЦ. 
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckOutlinedIcon color="primary" fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" fontSize="14px">
                                    Разница между РРЦ и дроп-ценой - это и есть ваш доход по заказу.
                                </Typography>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                            Как происходит оплата заказов на платформе?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <Typography variant='body2' paragraph>
                                Рассмотрим по шагам три основных варианта оплаты и отправки заказов.
                            </Typography>
                            <Typography variant="body1" paragraph fontSize="15px" style={{ color: '#DB4C19', fontWeight: 400 }}>
                                1. Отправка с наложенным платежом (наложка)
                            </Typography>
                            <Typography variant="body2" fontSize="14px" style={{ fontWeight: 500 }}>
                                Процесс:
                            </Typography>
                            <ol style={{ lineHeight: '2.5em', marginTop: '8px' }}>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Вы оформляете заказ на платформе, и указываете сумму наложки (цена, по которой вы продали товар)
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Мы отправляем заказ вашему клиенту Новой Почтой и вносим № ТТН в заказ
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Вы передаете ТТН из заказа вашему клиенту
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Клиент оплачивает стоимость товара и доставки при получении на почте
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Наложка зачисляется на банковский счет платформы
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Вам начисляется сумма заработка на дроп-баланс
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        После чего вы можете запросить выплату средств на вашу карту/счет
                                    </Typography>
                                </li>
                            </ol>

                            <Typography variant="body2" fontSize="14px" style={{ fontWeight: 500 }}>
                                Плюсы:
                            </Typography>
                            <ul style={{ lineHeight: '2.5em', marginTop: '8px' }}>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Увеличивает доверие клиентов, так как они платят только после получения товара
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Вам не нужно показывать доходы по своей карте и вести бухгалтерию
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        CRM система платформы контролирует статусы заказа, доставки, оплат и ваших доходов
                                    </Typography>
                                </li>
                            </ul>

                            <Typography variant="body2" fontSize="14px" style={{ fontWeight: 500 }}>
                                Минусы:
                            </Typography>
                            <ul style={{ lineHeight: '2.5em', marginTop: '8px' }}>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Клиенты могут просто не забрать посылку
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Вам придется оплатить стоимость доставки и возврата товара поставщику в случае отказа
                                    </Typography>
                                </li>
                            </ul>

                            <Typography variant="body2" fontSize="14px" style={{ fontWeight: 500 }}>
                                Рекомендация:
                            </Typography>
                            <ul style={{ lineHeight: '2.5em', marginTop: '8px' }}>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Берите с клиента оплату, покрывающую стоимость доставки, это примерно 100 грн. Оставшуюся сумму укажите как наложку. По нашей статистике, отказ от заказов с оплатой в 100 грн составляет не более 1%
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Следите за статусом отправки заказа Новой Почтой, напомните клиенту о его заказе на Новой Почте
                                    </Typography>
                                </li>
                            </ul>

                            <Typography variant="body1" paragraph style={{ color: '#DB4C19', fontWeight: 400 }}>
                                2. Отправка с предоплатой на платформе
                            </Typography>
                            <Typography variant="body2" fontSize="14px" style={{ fontWeight: 500 }}>
                                Процесс:
                            </Typography>
                            <ol style={{ lineHeight: '2.5em', marginTop: '8px' }}>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Клиент оплачивает заказ вам на карту или счет по цене РРЦ 
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Вы оформляете заказ на платформе с предоплатой товара по вашей дроп-цене
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Мы отправляем товар клиенту и вносим ТТН в заказ
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Вы передаете ТТН клиенту
                                    </Typography>
                                </li>
                            </ol>

                            <Typography variant="body2" fontSize="14px" style={{ fontWeight: 500 }}>
                                Плюсы:
                            </Typography>
                            <ul style={{ lineHeight: '2.5em', marginTop: '8px' }}>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Гарантированные деньги заранее, минимальный риск возвратов
                                    </Typography>
                                </li>
                            </ul>

                            <Typography variant="body2" fontSize="14px" style={{ fontWeight: 500 }}>
                                Минусы:
                            </Typography>
                            <ul style={{ lineHeight: '2.5em', marginTop: '8px' }}>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Не все клиенты хотят вносить предоплату. Предложите клиенту оплату в размере 100 грн, это примерная стоимость доставки товара Новой Почтой. Даже если клиент не заберет товар, то вы не попадете на доставку
                                    </Typography>
                                </li>
                            </ul>

                            <Typography variant="body1" paragraph style={{ color: '#DB4C19', fontWeight: 400 }}>
                                3. OLX доставка, Пром-оплата
                            </Typography>
                            <Typography variant="body2" fontSize="14px" style={{ fontWeight: 500 }}>
                                Процесс:
                            </Typography>
                            <ol style={{ lineHeight: '2.5em', marginTop: '8px' }}>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Клиент выбирает товар на OLX и оплачивает через встроенную систему оплаты OLX-оплата 
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Деньги списываются с его карты на счет OLX
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        OLX генерирует ТТН новой почты в кабинете продавца
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Вы делаете заказ на платформе, выбираете способ оплаты OLX-доставка и вносите ТТН в заказ
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Оплачиваете заказ по дроп-цене
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        После получения заказа на Новой Почте вам OLX зачисляет оплату по цене продажи
                                    </Typography>
                                </li>
                            </ol>

                            <Typography variant="body2" fontSize="14px" style={{ fontWeight: 500 }}>
                                Описание:
                            </Typography>
                            <ul style={{ lineHeight: '2.5em', marginTop: '8px' }}>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Клиент не платит за товар, пока не получит его на Новой Почте. Продавец застрахован от отказа клиента и расходов на доставку и возврат товара
                                    </Typography>
                                </li>
                            </ul>

                            <Typography variant="body2" fontSize="14px" style={{ fontWeight: 500 }}>
                                Плюсы:
                            </Typography>
                            <ul style={{ lineHeight: '2.5em', marginTop: '8px' }}>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Удобство для клиента, и дополнительная защита при оплате для продавца 
                                    </Typography>
                                </li>
                            </ul>

                            <Typography variant="body2" fontSize="14px" style={{ fontWeight: 500 }}>
                                Минусы:
                            </Typography>
                            <ul style={{ lineHeight: '2.5em', marginTop: '8px' }}>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Работает только на площадке OLX, это их собственная фича
                                    </Typography>
                                </li>
                            </ul>

                            <Typography variant="body2" fontSize="14px" style={{ fontWeight: 500 }}>
                                Рекомендация от нас:
                            </Typography>
                            <ul style={{ lineHeight: '2.5em', marginTop: '8px' }}>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Размещайте товары не только на OLX, но и на Prom.ua - там есть аналогичный сервис безопасных оплат ПРОМ-оплата
                                    </Typography>
                                </li>
                            </ul>
                            <Typography variant="body1" paragraph style={{ fontWeight: 500 }}>
                                Пром-оплата работает аналогично OLX-доставке, если вы продаете товары на маркетплейсе Prom.ua и подключили Пром-оплату.
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                            Сколько я заработал на платформе?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckOutlinedIcon color="primary" fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" fontSize="14px">
                                    В разделе Дроп-баланс отображаются ваши доходы, расходы и выплаты. <br/>Здесь вы можете видеть, сколько заработали и какие выплаты были сделаны.
                                </Typography>
                            </ListItem>
                            <img
                                src={`${imagesUrl}/2c03104e-b6b8-4999-832e-32f7be5b3f24.png`}
                                alt="image-20240531-155915.png"
                                style={{ display: 'block', width: '80%', margin: '16px auto' }}
                            />
                        </List>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                            Как я могу вывести деньги за свои продажи?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckOutlinedIcon color="primary" fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" fontSize="14px">
                                    Если на вашем дроп-балансе есть доступная сумма, вы можете запросить выплату. <br/>Мы зачислим деньги на карту или счет, указанный в вашем профиле.
                                </Typography>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                            Какие преимущества я получу, работая с вашей платформой?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <Typography variant='body2' paragraph>
                                Работая с нами, вы получите:
                            </Typography>
                            <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckOutlinedIcon color="primary" fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" fontSize="14px">
                                    Доступ к широкому ассортименту товаров и поставщиков
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckOutlinedIcon color="primary" fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" fontSize="14px">
                                    Импорт товаров с платформы на популярные маркетплейсы, такие как Prom.ua, Rozetka и пр. или на свой сайт
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckOutlinedIcon color="primary" fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" fontSize="14px">
                                    Оптовые цены на все товары от 1 единицы
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckOutlinedIcon color="primary" fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" fontSize="14px">
                                    Отправки товаров с нашего склада непосредственно вашему покупателю
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckOutlinedIcon color="primary" fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" fontSize="14px">
                                    Выплаты вашего дохода по запросу
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckOutlinedIcon color="primary" fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" fontSize="14px">
                                    Все бухгалтерские и юридические вопросы, такие как получение наложек и ведение налоговой отчетности, берем на себя, вы занимаетесь только продажами
                                </Typography>
                            </ListItem>
                        </List>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                            Какие бонусы я получу при работе с Prom.ua?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckOutlinedIcon color="primary" fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" fontSize="14px">
                                    {"Разместив понравившиеся товары с нашей платформы на маркетплейсе Prom.ua по "}
                                    <a href="https://prom.ua/reg_fr/154185" target="_blank" rel="noopener noreferrer" style={{ color: '#DB4C19', textDecoration: 'none' }}>
                                        ссылке
                                    </a>
                                    {", вы получите 500 грн бонусов на рекламный бюджет от Prom"}
                                </Typography>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                            Как связаться с поддержкой?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckOutlinedIcon color="primary" fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="body2" fontSize="14px">
                                    Если у вас возникли вопросы или проблемы, вы можете написать нам в
                                    <Link href="https://t.me/ceodropshippingua" target="_blank" rel="noopener noreferrer" style={{ color: '#2792E0', textDecoration: 'none', fontSize: '14px' }}>
                                    {' телеграм '}
                                    </Link>
                                    или на почту. <br/>Никаких ботов, в поддержке только живые люди 🙂
                                </Typography>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>

                </Container>
            </Paper>
    );
}

export default QuestionAnswer
