import {
  apiWithdrawalPath,
  apiWithdrawalsPath,
  apiWithdrawPath
} from 'config/apiPath'

import instance from './axiosProvider'


export const withdraw = async () => {
  try {
    const res = await instance.post(apiWithdrawPath);
    return res.data;
  } catch (err) {
    throw new Error(err)
  }
}
export const getAllWithdrawals = async () => {
  try {
    const res = await instance.get(apiWithdrawalsPath);
    return res.data;
  } catch (err) {
    throw new Error(err)
  }
}
export const getWithdrawal = async (id) => {
  try {
    const res = await instance.get(apiWithdrawalPath.replace(':id', id));
    return res.data;
  } catch (err) {
    throw new Error(err)
  }
}
export const updateWithdrawal = async (id, data) => {
  try {
    const res = await instance.post(apiWithdrawalPath.replace(':id', id), data);
    return res.data;
  } catch (err) {
    throw new Error(err)
  }
}