import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'

import FeedDetails from './FeedDetails'
import { styles } from './FeedDetails.styles'

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FeedDetails))
