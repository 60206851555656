const apiCustomer = 'customer/'
export const apiLoginPath = `${apiCustomer}login/`
export const apiGoogleLoginPath = `${apiCustomer}loginViaGoogle/`
export const apiSignupPath = `${apiCustomer}register/`
export const apiRecoveryPasswordPath = `${apiCustomer}password_recovery/`
export const apiLogoutPath = `${apiCustomer}logout/`
export const apiProfilePath = `${apiCustomer}details/`
export const apiDebtPath = `${apiCustomer}debt/`
export const apiUploadAvatarPath = `${apiCustomer}upload-avatar/`
export const apiprofileUpdatePath = `${apiCustomer}update/`
export const apiNewOrderPath = 'neworder/'
export const apiStatistic = 'statistic/'
export const apiProductsPath = 'products/'
export const apiProductExportPath = 'products/export'
export const apiProductStatistics = 'products/statistics'
export const apiProductsImportPath = 'products/import'
export const apiMappersPath = 'mappers'
export const apiProductsWith1sPath = 'products_with_1c'
export const apiCategoriesPath = 'categories/'
export const apiProductSearchAutocompletePath = 'product_autocomplete/'
export const apiOrders = 'orders/'
export const apiOrderDetails = 'orders/:id'
export const apiOrderEmail = 'orders/email/:id'
export const apiDeliveryCitiesList = `${apiOrders}shipping_cities/`
export const apiDeliveryWarehousList = `${apiOrders}shipping_warehous/`
export const apiPaymentMethodList = `${apiOrders}payment_method/`
export const apiPaymentLog = 'paymentlog/'
export const apiResetPasswordPath = `${apiCustomer}password_reset/`
export const apiProductDetailsPath = 'product/:id'
export const apiCheckoutOrderPath = 'orders/'
export const apiSaveOrderTemplate = 'order_template/'
export const apiAdminPath = 'admin/'
export const apiUserListPath = `${apiAdminPath}customers/`
export const apiAdminCustomer = `${apiAdminPath}customer/`
export const apiACustomer = `${apiAdminPath}customer/:id`
export const apiSetUserStatusPath = `${apiAdminCustomer}status/`
export const apiSetTokenPath = `${apiUserListPath}token1c/:id `
export const apiSetCodePath = `${apiUserListPath}code1c/:id`
export const apiSetRolesPath = `${apiUserListPath}roles/:id`
export const apiEmailConfirmPath = `${apiCustomer}email_confirmation/`
export const apiDeliveryTypeList = `${apiOrders}shipping_method/`
export const apiDeliveryStreetsPath = 'np/streets/'
export const apiCategoriesLevelPath = 'categories/level/:level'
export const apiSubcategoriesPath = 'categories/sub/:id'
export const apiSubcategoryProductsPath = 'categories/category-products/:id'
export const apiDashboardPath = 'dashboard'
export const apiWithdrawPath = 'withdraw'
export const apiWithdrawalsPath = 'withdrawals'
export const apiProvidersPath = 'providers'
export const apiProviderPath = 'providers/:id'
export const apiFeedsPath = 'feeds'
export const apiFeedPath = 'feeds/:id'
export const apiWithdrawalPath = 'withdrawal/:id'
