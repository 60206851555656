// import { createMuiTheme } from '@material-ui/core/styles'
import { createTheme } from '@material-ui/core/styles'
import defaultTheme from '@material-ui/core/styles/defaultTheme'
import red from '@material-ui/core/colors/red'

const theme = defaultTheme

export const overrides = appTheme => ({
  MuiPaper: {
    root: {
      backgroundColor: '#262626',
      '& .padded': {
        padding: 16,
        [theme.breakpoints.down('sm')]: {
          padding: 6,
        },
      },
    },
  },

  MuiGrid: {
    root: {
      width: '100%',
    },
  },

  MuiListItemIcon: {
    root: {
      minWidth: '43px',
    }

  },

  MuiChip: {
    root: {
      borderRadius: 3,
      cursor: 'pointer',
      textDecoration: 'none',
      height: '24px'
    },
    deleteIcon: {
      color: '#2792E0',
      '&:hover': {
          color: appTheme.palette.warning.main, 
        },
    },
  },

  MuiTooltip: {
    tooltip: {
      backgroundColor: appTheme.palette.primary.main,
      fontSize: '12px',
      color: '#f0f2f3',
    },
    arrow: {
      color: appTheme.palette.primary.main,
    },
  },

  MuiSvgIcon: {
    root: {
      '&.warning': {
        color: appTheme.palette.warning.main,
      },
    },
  },

  MuiIconButton: {
    root: {
      color: '#f0f2f3',
      '&:hover': {
        color: '#db4c19',
      },
    },
  },

  MuiButton: {
    root: {
      fontSize: 14,
      '&.error': {
        backgroundColor: appTheme.palette.error.main,
        // color: appTheme.palette.text.secondary,
      },
      '&.warning': {
        backgroundColor: appTheme.palette.warning.main,
        // color: appTheme.palette.text.secondary,
      },
    },
    
    // raised: {
    //   fontWeight: 400,
    // },

    outlinedSecondary: {
      borderColor: '#fff',
      color: '#fff',
    },
    contained: {
      // backgroundColor: appTheme.palette.primary.main,
        '&.Mui-disabled': {
          backgroundColor: '#b3b3b3', 
        },
      },
  },
  MuiOutlinedInput: {
      input: {
        padding: '0.65rem 1.25rem',
      },
      root: {
        '&:hover $notchedOutline': {
          borderColor: '#DB4C19', 
        },
        '&$focused $notchedOutline': {
          borderColor: '#DB4C19', 
        },
      },
      notchedOutline: {
        borderColor: '#575555', 
      },
  },

  MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #575555', 
        },
      },
    },

  MuiInputBase: {
    input: {
      color: '#f0f2f3',
      fontSize: '14px',
    },
    // underline: {
    //     '&:before': {
    //       borderBottom: '1px solid #575555', 
    //     },
    //   },
  },
  
  MuiInputLabel: {
    outlined: {
      transform: 'translate(14px, 13px) scale(1)',
    },
  },

  MuiSelect: {
    icon: {
      color: '#cdcfd1',
    },
  },
  // MuiTableRow: {
  //   root: {
  //     color: '#34495f',
  //     '&$hover:hover': {
  //     },
  //   },
  // },
  

  MuiToolbar: {
    root: {
      justifyContent: 'end',
    },
  },  
  MUIDataTableToolbar: {
    actions: {
      flex: 'unset',
    },
    left: {
      justifyContent: 'flex-end',
      textAlign: 'left',
    },
  },
  MuiTablePagination: {
    menuItem: {
      fontSize: '14px',
      '&:hover': {
        color: '#db4c19',
      },
    },
    toolbar: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    actions: {
      [theme.breakpoints.down('xs')]: {
        margin: 0,
      },
    },
    input: {
      [theme.breakpoints.down('xs')]: {
        marginRight: 8,
      },
    },
  },

  MuiTableCell: {
    root: {
      fontFamily: 'inherit',
      fontSize: '14px',
      borderBottom: '1px solid #575555',
    },
    body: {
      color: 'inherit',
    },
    [theme.breakpoints.down('sm')]: {
          fontSize: 12,
        },
  },
  MuiCard: {
    root: {
      width: '100%',
    },
  },
  MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: '16px',
        },
      },
    },
  MuiTypography: {
    gutterBottom: {
      minHeight: '4rem',
    },
  },

  MuiMenu: {
    paper: {
      background: '#141313',
    },
  },

  MUIDataTableViewCol: {
    label: {
      fontSize: '12px', 
    },
      root: {
        background: '#141313', 
        // fontSize: '12px', 
        borderRadius: '4px',
      },
  },

  MUIDataTableFilter: {
    root: {
      padding: '24px',
      backgroundColor: '#141313',
  },
  },  
  

  MUIDataTableBodyCell: {
    // cellStacked: {
    //   [theme.breakpoints.down('sm')]: {
    //     display: 'inline-block',
    //     wordWrap: 'break-word',
    //     whiteSpace: 'unset',
    //     width: '50%',
    //     height: 'auto',
    //     borderBottom: 'none',
    //   },
    // },
    // responsiveStacked: {
    //   [theme.breakpoints.down('sm')]: {
    //     width: '50% !important',
    //     textAlign: 'end',
    //     height: 'auto',
    //     borderBottom: 'none',
    //     wordWrap: 'break-word',
    //     whiteSpace: 'unset',
    //   },
    // },
    cellStackedSmall: {
      [theme.breakpoints.down('sm')]: {
        display: 'inline-block',
        wordWrap: 'break-word',
        whiteSpace: 'unset',
        width: '50%',
        height: 'auto',
        borderBottom: 'none',
        boxSizing: 'border-box',
        textAlign: 'left',
      },
    },
    stackedCommon: {
      [theme.breakpoints.down('sm')]: {
        width: '50%',
        textAlign: 'start',
        fontSize: 14,
        height: 'auto',
        borderBottom: 'none',
        wordWrap: 'break-word',
        whiteSpace: 'unset',
        boxSizing: 'border-box',
      },
    },
    responsiveStackedSmall: {
      [theme.breakpoints.down('sm')]: {
        width: '50%',
        textAlign: 'end',
        height: 'auto',
        borderBottom: 'none',
        wordWrap: 'break-word',
        whiteSpace: 'unset',
        boxSizing: 'border-box',
      },
    },
  },
  MuiTableRow: {
    root: {
      cursor: 'pointer'
    },
      head: {
        '& .MuiTableCell-head': {
          backgroundColor: '#262626', 
          // fontSize: '15px',
        },
      },
  },
  
  // MUIDataTableHeadCell: {
  //     toolButton: {
  //       fontSize: '15px', 
  //     },
  //   },
})



const appTheme = {
  // palette: {
  //   primary: { main: '#4a5ab9', contrastText: '#fff' },
  //   secondary: { main: '#18c5a9', contrastText: '#fff' },
  //   warning: { main: '#f39c12' },
  //   error: { main: red[600] },
  //   text: { primary: '#34495f', secondary: '#b4bcc8' },
  //   contrastThreshold: 3,
  //   tonalOffset: 0.2,
  //   menuBackground: '#304357',
  // },
  palette: {
    primary: { main: '#db4c19', contrastText: '#f0f2f3' },
    secondary: { main: '#2792E0', contrastText: '#f0f2f3' },
    info: {main: '#2AC77B', contrastText: '#f0f2f3'},
    warning: { main: red[600] },
    error: { main: red[600] },
    text: { primary: '#f0f2f3', secondary: '#cdcfd1' },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    menuBackground: '#141313',
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Montserrat',
      'Ubuntu',
      'Cantarell',
      'Open Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),

  },
  mixins: {

  },
}

appTheme.overrides = overrides(appTheme)

// const newTheme = createMuiTheme(appTheme)
const newTheme = createTheme(appTheme)
newTheme.overrides = overrides(newTheme)

export default newTheme
