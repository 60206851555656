const catalogCategoryProductsStyles = {
    cardContainer: {
        display: 'flex',
        gap: '20px', 
        flexWrap: 'wrap',
        flexDirection: 'row',
    },

    card: {
        display: 'flex',
        textAlign: 'center',
        width: '100%',
        background: '#fff',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    
    cardProductList: {
        display: 'flex',
        textAlign: 'center',
        width: '100%',
        background: '#262626',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },

    cardContent: {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        background: '#262626',
        padding: '10px',
    },
    cardMedia: {
        maxWidth: '100%',
        maxHeight: '120px',
        width: 'auto',
        margin: 'auto',
    },

    listItemText: {
        color: '#f0f2f3',
        textTransform: 'none',
        fontSize: '14px',
    },
    
    listItemIcon: {
        color: '#DB4C19',
    },

    gridItem: {
        textAlign: 'center',
        background: '#262626',
        display: 'flex',
        // flexDirection: 'column',
        borderRadius: '4px',
        //   flexWrap: 'wrap',
    },

    centerGridItem: {
        textAlign: 'center',
        background: '#262626',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '4px',
        justifyContent: 'center',
        //   flexWrap: 'wrap',
    },

    // list: {
    //     width: '100%',
    // },

    exportButton: {
        margin: 'auto 16px 16px 16px', 
    },

    spanProducts: {
        color: '#f0f2f3',
        fontSize: '14px',
    },
};

export default catalogCategoryProductsStyles;