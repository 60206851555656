export const signUpPath = '/shipper/register'
export const signUpSupplierPath = '/supplier/register'
export const signInSupplierPath = '/supplier/login'
export const registerSuccessPath = `${signUpPath}/success`
export const registerSuccessSupplierPath = `${signUpSupplierPath}/success`
export const recoveryPasswordPath = '/shipper/password_recovery'
export const recoveryPasswordSupplierPath = '/supplier/password_recovery'
export const recoveryPasswordSuccessPath = `${recoveryPasswordPath}/success`
export const recoveryPasswordSuccessSupplierPath = `${recoveryPasswordSupplierPath}/success`
export const signInPath = '/shipper/login'
export const ordersPath = '/orders'
export const withdrawalsPath = '/withdrawals'
export const catalogViewPath = '/catalog/:id'
export const catalogCategoryViewPath = '/catalog/category/:id'
export const catalogCategoryProductsViewPath = '/catalog/category-products/:id'
export const statisticPath = '/dashboard'
export const profilePath = '/profile'
export const productsPath = '/products'
export const productsProviderPath = '/products/provider/:provider'
export const productImportPath = '/import'
export const productXmlImportPath = '/xml-import'
export const newOrderPath = `${productsPath}/newOrder`
export const productsCategoriesPath = '/products-categories'
export const productViewPath = '/product/:id'
export const paymentLogPath = '/paymentlog'
export const resetPassword = '/password_reset'
export const preorderPath = '/preorder'
export const userListPath = '/users'
export const userViewPath = '/user/:id'
export const providerListPath = '/providers'
export const providerDetailsPath = '/providers/:id'
export const feedListPath = '/feeds'
export const feedDetailsPath = '/feeds/:id'
export const confirmEmailPath = '/email_confirmation'
export const orderDetailsPath = '/order_details/:id'
export const withdrawalDetailsPath = '/withdrawals/:id'
