import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'

const MinimalTabs = withStyles({
  root: {
    borderBottom: '1px solid #575555',
  },
  indicator: {
    backgroundColor: '#DB4C19',
  },
})(Tabs)

export default MinimalTabs
