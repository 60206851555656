import React, {useEffect, useState} from 'react'
import Paper from '@material-ui/core/Paper'
import DataTable from 'mui-datatables'
import {getAllWithdrawals, getWithdrawal, updateWithdrawal} from "../../../services/api/withdrawal.service";
import {orderDetailsPath, withdrawalDetailsPath} from "../../../config/routes";
import {useHistory} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {Box, MenuItem, Select} from "@material-ui/core";
import {onlyAdminOrGreater} from "../../../config/roles";
import Button from "@material-ui/core/Button";
import Table from "../OrderDetails/Table";
import classNames from "classnames";
import {updateOrder} from "../../../services/api/order.service";
import { showSnack } from "../../../storage/actions/snack.actions";
import { formatPrice } from 'components/parts/FormParts/PriceFormatter';


const WithdrawalDetails = (props) => {
    const id = props?.match?.params?.id
    const classes = props?.classes

    const [withdrawal, setWithdrawal] = useState([]);
    const [formData, setFormData] = useState({})

    const fields = [
        {
            key: 'id',
            name: 'Номер',
            visible: true,
        },
        {
            key: 'customer_name',
            name: 'Шиппер',
            visible: onlyAdminOrGreater(),
            customBodyRender: (value, row) => {
                return <Typography style={{ whiteSpace: 'break-spaces', fontSize: '14px', color: '#cdcfd1' }} align='right'>{withdrawal.customer_name} {withdrawal.customer_lastname}</Typography>
            }
        },
        {
            key: 'sum',
            name: 'Сумма',
            visible: true,
            customBodyRender: (value) => (
                <Typography style={{ whiteSpace: 'break-spaces', fontSize: '14px', color: '#cdcfd1' }} align='right'>
                    {formatPrice(value, 2)} ₴
                </Typography>
            ),
        },
        {
            key: 'status',
            name: 'Статус',
            visible: true,
        },
        {
            key: 'created_at',
            name: 'Дата создания',
            visible: true,
            customBodyRender: (value) => (
                <Typography style={{ whiteSpace: 'break-spaces', fontSize: '14px', color: '#cdcfd1' }} align='right'>
                    {new Date(value).toLocaleDateString()}
                </Typography>
            ),
        },
    ];

    useEffect(() => {
        getWithdrawal(id).then((data) => {
            setWithdrawal(data);
        })
    }, []);

    const onSubmit = (e) => {
        e.preventDefault()
        updateWithdrawal(id, formData).then((success) => {
            if (success) {
                showSnack({
                    variant: 'success',
                    message: `Выплата успешно обновлена!`,
                })
            }
        })
    }

    const renderDetail = (field, key) => {

        if (onlyAdminOrGreater() && field.key === 'status') {
            return (
                <div key={field.key} className={classNames({ [classes.odd]: !(key % 2) }, classes.detail)}>
                    <Typography style={{ whiteSpace: 'nowrap', fontSize: '16px', color: '#f0f2f3'}} >{field.name} :</Typography>
                    <Select
                        variant='outlined'
                        value={formData?.status || withdrawal[field.key] || 'Передан поставщику'}
                        onChange={(event) => {
                            setFormData({...formData, status: event.target.value})
                        }}
                    >
                        {['Запрос отправлен', 'Выплачено'].map(status => (
                            <MenuItem className={classNames(classes.menuItem)} key={status} value={status} style={{ fontSize: '14px' }}>
                                {status}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            )
        }


        return <div key={field.key} className={classNames({ [classes.odd]: !(key % 2) }, classes.detail)}>
            <Typography style={{ whiteSpace: 'nowrap', fontSize: '16px', color: '#f0f2f3' }} >{field.name} :</Typography>
            {field.customBodyRender ? field.customBodyRender(withdrawal[field.key], withdrawal) : <Typography style={{ whiteSpace: 'break-spaces', fontSize: '14px', color: '#cdcfd1' }} align='right'>{withdrawal[field.key]}</Typography>}
        </div>
    }

    return (
        <Paper style={{ padding: '16px 6px'}}>
            <Typography align='center' variant='h4' color='primary'>
                Выплата № {withdrawal.id}
            </Typography>
            <div className={classes.detailsList}>
                <form onSubmit={onSubmit}>
                    {fields.filter(field => field.visible).map(renderDetail)}
                    <Box display={onlyAdminOrGreater() ? "flex" : "none"} justifyContent="flex-end" sx={{ m: 2 }}>
                        <Button variant='containedPrimary' type='submit'>Сохранить</Button>
                    </Box>
                </form>
            </div>
        </Paper>
    )
}

export default WithdrawalDetails
