import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'

import WithdrawalDetails from './WithdrawalDetails'
import { styles } from './WithdrawalDetails.styles'

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WithdrawalDetails))
