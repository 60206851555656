const categoryCardStyles = {
  card: {      
        background: '#fff',
  },
  
  gridItem: {
        textAlign: 'center',
        display: 'flex',
        borderRadius: '4px',
    },
  
  cardMedia: {
    maxWidth: '100%',
    maxHeight: '120px',
    width: 'auto',
    margin: 'auto',
    background: '#fff',
  },
  
  cardContent: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: 35,
    padding: '5px',
    background: '#262626',
  },
  list: {
    background: '#262626',
  },
    listItemIcon: {
      color: '#DB4C19',
  },
  listItemText: {
    fontSize: '14px',
    textTransform: 'none',
    lineHeight: '1.4',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    },
};

export default categoryCardStyles;