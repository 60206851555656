export const formatPrice = (price) => {
  if (price === undefined || price === '') {
    console.error('Price is undefined or empty');
    return 'Invalid Price';
  }

  const priceString = typeof price === 'string' ? price : price.toString();

  const numericPrice = parseFloat(priceString.replace(/[^\d.]/g, ''));

  if (isNaN(numericPrice)) {
    console.error(`Invalid price: ${price}`);
    return 'Invalid Price';
  }

  const roundedPrice = Math.round(numericPrice);

  const formattedPrice = roundedPrice.toString();

  return formattedPrice;
};