import React from 'react';
// import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { chipStyles } from './ChipStyles';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { onlyAdminOrGreater } from "../../../config/roles";
import { withdrawalDetailsPath } from "../../../config/routes";
import { formatPrice } from 'components/parts/FormParts/PriceFormatter';


const useStyles = makeStyles((theme) => ({
}));

const WithdrawalsTable = ({ data }) => {
  const classes = useStyles();
  const chipClasses = chipStyles();
  const history = useHistory();

  const handleRowClick = (withdrawalId) => {
    history.push(withdrawalDetailsPath.replace(':id', withdrawalId));
  };

  if (!data || data.length === 0) {
    return (
      <Typography color="textSecondary" variant="h6" style={{ padding: '20px 5px' }}>
        Запросов на выплату пока нет
      </Typography>
    );
  }

  return (
    // <TableContainer component={Paper} style={{ marginTop: '20px' }}>
      <Table style={{ display: _.isEmpty(data) ? 'none' : 'table', marginTop: '20px' }}>
        <TableHead>
          <TableRow>
            <TableCell style={{ padding: '10px', fontSize: '16px' }}>Сумма</TableCell>
            <TableCell style={{ padding: '10px', fontSize: '16px', display: onlyAdminOrGreater() ? 'table-cell' : 'none' }}>Шиппер</TableCell>
            <TableCell style={{ padding: '10px', fontSize: '16px' }}>Статус</TableCell>
            <TableCell style={{ padding: '10px', fontSize: '16px' }}>Дата запроса</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.slice().reverse().map((withdrawal) => (
            <TableRow key={withdrawal.id} onClick={() => handleRowClick(withdrawal.id)}>
              <TableCell style={{ padding: '10px', fontSize: '14px'}}>
                {`${formatPrice(withdrawal.sum)} ₴`}
              </TableCell>
              <TableCell style={{ padding: '10px', fontSize: '14px' , display: onlyAdminOrGreater() ? 'table-cell' : 'none' }}>
                {withdrawal.customer_name} {withdrawal.customer_lastname}
              </TableCell>
              <TableCell style={{ padding: '15px 10px' }}>
                <Chip
                  label={withdrawal.status}
                  className={`${chipClasses.chip} ${
                    withdrawal.status === 'Запрос отправлен' ? chipClasses.requestSent :
                      withdrawal.status === 'Выплачено' ? chipClasses.paid :
                        withdrawal.status === 'Отменено' ? chipClasses.canceled : ''
                    }`}
                />
              </TableCell>
              <TableCell style={{ padding: '10px' }}>
                {`${new Date(withdrawal.created_at).toLocaleDateString()}`}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    // </TableContainer>
  );
};

export default WithdrawalsTable;