import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

export const chipStyles = makeStyles((theme) => ({
  chip: {
    borderRadius: '10rem',
    color: '#fff',
  },
  pending: {
    backgroundColor: '#6f788f',
  },

  сonfirmed: {
    backgroundColor: '#098f9c',
  },
  shipped: {
    backgroundColor: theme.palette.secondary.main,
  },
  completed: {
    backgroundColor: theme.palette.info.main,
  },
  requestSent: {
    backgroundColor: theme.palette.secondary.main,
  },
  paid: {
    backgroundColor: theme.palette.info.main,
  },
  canceled: {
    backgroundColor: theme.palette.warning.main,
  },
  pendingPayment: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const renderStatusChip = (status, classes) => {
  let chipClass = classes.chip; 
  if (status === 'Запрос отправлен') {
    chipClass += ` ${classes.requestSent}`;
  } else if (status === 'Выплачено') {
    chipClass += ` ${classes.paid}`;
  } else if (status === 'Отменено') {
    chipClass += ` ${classes.canceled}`;
  } else if (status === 'Передан поставщику') {
    chipClass += ` ${classes.pending}`;
  } else if (status === 'Выполнен') {
    chipClass += ` ${classes.completed}`;
  } else if (status === 'Отправлен') {
    chipClass += ` ${classes.shipped}`;
  } else if (status === 'Подтвержден поставщиком') {
    chipClass += ` ${classes.сonfirmed}`;
  } else if (status === 'Ожидает предоплату') {
    chipClass += ` ${classes.pendingPayment}`;
  } else if (status === 'Отменен') {
    chipClass += ` ${classes.canceled}`;
  }

  return <Chip label={status} className={chipClass} />;
};