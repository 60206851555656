import React, {useEffect, useState} from 'react'
import {CardActions, CardMedia, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {getCategoriesWithSub} from "../../../../services/api/category.service";
import {useHistory} from "react-router-dom";
import {catalogCategoryProductsViewPath} from "../../../../config/routes";
import WorkOutlineOutlined from "@material-ui/icons/WorkOutlineOutlined";
import BrandingWatermarkOutlined from "@material-ui/icons/BrandingWatermarkOutlined";
import PersonOutlined from "@material-ui/icons/PersonOutlined";
import CategoryCard from "../CategoryCard";

const CatalogCategoryView = (props) => {
    const categoryId = props?.match?.params?.id
    const [subCategories, setSubCategories] = useState([]);
    const [category, setCategory] = useState({});
    const history = useHistory();

    useEffect(() => {
        getCategoriesWithSub(categoryId).then((data) => {
            setSubCategories(data.subCategories);
            setCategory(data.category);
        }).catch((err) => {
            console.log(err)
        })
    }, [categoryId]);

    return (
        <>
            <CardContent component="div">
                <Typography variant="h4" style={{ color: '#f0f2f3', display: 'flex', alignItems: 'center' }}>{category?.name}</Typography>
            </CardContent>
            <Grid container spacing={2}>
                {subCategories?.map(childCategory => <CategoryCard key={childCategory.id} category={childCategory} /> )}
            </Grid>
        </>
    );
}

export default CatalogCategoryView
