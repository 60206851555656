import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  amount: {
    marginBottom: theme.spacing(2),
    },
  centerButtons: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const WithdrawalDialog = ({ modalOpened, handleWithdrawClick, onClose, availableAmount }) => {
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby='edit-user-data'
      open={modalOpened}
      onClose={onClose}
      classes={{ paper: classes.root }}
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        Запросить выплату на сумму
        <div className={classes.amount}>{Math.round(availableAmount)} ₴?</div>
      </DialogTitle>
      <div className={classes.body}>
        <DialogActions className={classes.centerButtons}>
          <Button variant='outlined' color='primary' onClick={onClose}>
            Нет
          </Button>
          <Button variant='contained' color='secondary' onClick={handleWithdrawClick}>
            Да
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default WithdrawalDialog;