import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import RemoveShoppingCartOutlinedIcon from '@material-ui/icons/RemoveShoppingCartOutlined';
import { getDashboardData } from '../../../services/api/dashboard.service';

const EarningsCard = ({ amount, label, additionalLabel, status }) => {

    const getStatusInfo = () => {
        switch (status) {
            case 'available':
                return { icon: <AccountBalanceWalletOutlinedIcon />, color: '#2AC77B' };
            case 'pending':
                return { icon: <LocalShippingOutlinedIcon />, color: '#2792E0' };
            case 'expenses':
                return { icon: <RemoveShoppingCartOutlinedIcon />, color: '#e53935' };
            default:
                return { icon: null, color: '#000000' };
        }
    };

    const { icon, color } = getStatusInfo();

    const roundedAmount = Math.round(amount);

    return (
        <Card style={{ boxShadow: 'none', border: '1px solid #575555' }}>
            <CardContent>
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <div style={{ marginRight: 20, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        {icon ? (
                            React.cloneElement(icon, { style: { fontSize: 35, color, marginRight: 20, } })
                        ) : (
                            <AccountBalanceWalletOutlinedIcon style={{ fontSize: 35, color }} />
                        )}
                        <Typography variant="h5" component="div" style={{ fontWeight: 400 }}>
                            {roundedAmount} ₴
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body3" color="textPrimary" style={{ marginTop: '10px' }}>
                            {label}
                        </Typography>
                        <Typography variant="caption" color="textSecondary" style={{ marginTop: '5px' }}>
                            {additionalLabel}
                        </Typography>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default EarningsCard;

