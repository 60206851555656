import React from 'react'
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { getStorageItem, setStorageItem } from 'services/storage'

import {
    signInPath,
    registerSuccessPath,
    recoveryPasswordPath,
    signUpPath,
    signUpSupplierPath,
    recoveryPasswordSuccessPath,
    resetPassword,
    signInSupplierPath, recoveryPasswordSupplierPath, recoveryPasswordSuccessSupplierPath, registerSuccessSupplierPath,
} from 'config/routes'
import { ROOT_DOMAIN } from 'config/constants'

import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import AfterReg from './pages/AfterReg'
import Recovery from './pages/Recovery'
import RecoverySuccess from './pages/RecoverySuccess'
import RestorePassword from './pages/RestorePassword'
import SupplierSignUp from "./pages/SupplierSignUp";
import SupplierSignIn from "./pages/SupplierSignIn";
import SupplierRecovery from "./pages/SupplierRecovery";
import SupplierRecoverySuccess from "./pages/SupplierRecoverySuccess";

export default function () {
    const wasSupplier = JSON.parse(getStorageItem('was_supplier'));
  return (
    <Switch>
      <Route exact path={signUpPath} component={SignUp} />
      <Route exact path={signInSupplierPath} component={SupplierSignIn} />
      <Route exact path={signUpSupplierPath} component={SupplierSignUp} />
      <Route exact path={registerSuccessPath} component={AfterReg} />
      <Route exact path={registerSuccessSupplierPath} component={AfterReg} />
      <Route exact path={recoveryPasswordPath} component={Recovery} />
      <Route exact path={recoveryPasswordSupplierPath} component={SupplierRecovery} />
      <Route exact path={recoveryPasswordSuccessPath} component={RecoverySuccess} />
      <Route exact path={recoveryPasswordSuccessSupplierPath} component={SupplierRecoverySuccess} />
      <Route path={signInPath} component={SignIn} />
      <Route path={resetPassword} component={RestorePassword} />
        {wasSupplier ? <Redirect path={ROOT_DOMAIN} to={signInSupplierPath} /> : <Redirect path={ROOT_DOMAIN} to={signInPath} />}
    </Switch>
  )
}
