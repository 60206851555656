import { CLOSE_ROLE_DIALOG, OPEN_ROLE_DIALOG } from 'storage/constants/userRoleDialog.constants'

const defaultState = { open: false, userRoles: [], userId: null }

export default function (state = defaultState, action) {
  if (action.type === CLOSE_ROLE_DIALOG) {
    return defaultState
  }
  if (action.type === OPEN_ROLE_DIALOG) {
    return { ...action.payload, open: true }
  }
  return state
}
