import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { Typography, Container, List, ListItem, Box, ListItemIcon, IconButton, Button, Accordion, AccordionSummary, AccordionDetails, Link } from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Tooltip } from '@material-ui/core';
import TelegramIcon from '@material-ui/icons/Telegram';
import {apiBaseURL} from "../../../config/constants";
const imagesUrl = apiBaseURL.slice(0, -3) + 'images'

const SellOnPromUa = () => {
    const referralLink = "https://prom.ua/reg_fr/154185";

    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralLink).then(() => {
            alert('Ссылка скопирована в буфер обмена!');
        }).catch(err => {
            console.error('Ошибка при копировании: ', err);
        });
    };

    return (
        <Paper style={{ padding: '16px 6px' }}>
            <Container style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='h6' paragraph align='center' style={{ paddingBottom: 16 }}>
                    Продавать на
                    <a href="https://prom.ua/reg_fr/154185" target="_blank" rel="noopener noreferrer" style={{ color: '#DB4C19', textDecoration: 'none', marginLeft: 4 }}>
                        Prom.ua
                    </a>
                </Typography>
                <Typography variant="body1" paragraph style={{ fontWeight: 400 }}>
                    Зарегался на платформе, нашел интересные товары и даже знаешь как продавать по дропу? Отлично, но открытым остается вопрос: где брать заказы? Где продавать товары с платформы ? 
                </Typography>
                <Typography variant='h6' color='primary' paragraph style={{ fontWeight: 400 }}>
                    Разместите товары на Prom.ua и получите бонусы!
                </Typography>
                <Typography variant="body1" paragraph style={{ fontWeight: 400 }}>
                    Хотите начать продавать по дропу уже сегодня, не вкладывая большие деньги в собственный сайт? У нас есть отличное предложение для вас! 
                </Typography>
                <Typography variant="body1" paragraph style={{ fontWeight: 400 }}>
                    Разместите понравившиеся товары с нашей платформы на маркетплейсе Prom.ua по
                    <a href="https://prom.ua/reg_fr/154185" target="_blank" rel="noopener noreferrer" style={{ color: '#DB4C19', textDecoration: 'none', marginLeft: 4 }}>
                        ссылке
                    </a>
                    , и получите 500 грн бонусов на рекламный бюджет от Prom.ua!
                </Typography>
                <Typography variant='body1' style={{ fontWeight: 400, paddingTop: 20 }}>
                    Как получить бонус:
                </Typography>
                <div>
                    <ol style={{ lineHeight: '2.5em', marginTop: '8px' }}>
                        <li>
                            <Typography variant="body2" fontSize="14px">
                                Выберите товары в каталоге платформы, которые хотите продавать, добавьте их в XML-выгрузку
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" fontSize="14px">
                                Создайте сайт на Prom.ua перейдя по ссылке
                                <a href={referralLink} target="_blank" rel="noopener noreferrer" style={{ color: '#DB4C19', textDecoration: 'none', marginLeft: 4 }}>
                                    {referralLink}
                                </a>
                                <Tooltip title="Скопировать ссылку">
                                    <IconButton onClick={copyToClipboard} size="small" style={{ marginLeft: 6 }}>
                                        <FileCopyOutlinedIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body2" fontSize="14px">
                                Вставьте XML-выгрузку, сгенерированную в каталоге платформы, в разделе Импорт в админке Прома, и импортируйте товары с платформы на сайт
                            </Typography>
                        </li>
                        <img
                            src={`${imagesUrl}/sell-on-prom-1.png`}
                            style={{ display: 'block', width: '80%', margin: '16px auto' }}
                        />
                        <li>
                            <Typography variant="body2" fontSize="14px">
                                Получите 500 грн на свой рекламный бюджет от Prom.ua и начните привлекать покупателей!
                            </Typography>
                        </li>
                    </ol>
                </div>
                <Typography variant="body1" paragraph style={{ fontWeight: 400 }}>
                    Начните прямо сейчас! Импортируйте товары и воспользуйтесь всеми преимуществами Prom.ua и нашего бонуса.
                </Typography>
                <Box display="flex" justifyContent="center">
                    <Button
                        href="https://prom.ua/reg_fr/154185"
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="contained"
                        color="primary"
                        style={{ textDecoration: 'none', marginLeft: 8, width: 'fit-content' }}
                    >
                        Выгрузить товары на Prom
                    </Button>
                </Box>
                <Typography variant="body1" paragraph style={{ fontWeight: 400, paddingTop: 20 }}>
                    Не упустите возможность расширить свой бизнес и увеличить продажи с помощью нашего специального предложения! Удачных продаж!
                </Typography>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Box style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
                            <Typography variant='h6' style={{ fontWeight: 400 }}>
                                Мне нужно больше информации
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <Typography variant='body1'>
                                Маркетплейс Prom.ua - самый простой и доступный вариант создать свой интернет-магазин.
                            </Typography>
                            <ul style={{ lineHeight: '2.5em', marginTop: '6px' }}>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Без программистов
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Без больших затрат
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Без ожидания раскрутки сайта
                                    </Typography>
                                </li>
                            </ul>
                            <Typography variant="body2" paragraph style={{ fontWeight: 400 }}>
                                Задача Прома - дать вам простую админку для управления товарами, которые вы выгрузите с платформы на Пром, и обрабатывать заказы от ваших клиентов.
                            </Typography>
                            <Typography variant='body2'>
                                Возможно, вы уже знаете, что:
                            </Typography>
                            <ul style={{ lineHeight: '2.5em', marginTop: '8px' }}>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Создать свой сайт - это <Box component="span" fontWeight="fontWeightBold">дорого</Box>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Должны быть навыки в интернет-маркетинге, электронной коммерции и IT
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Раскручивать свой сайт в интернете <Box component="span" fontWeight="fontWeightBold">сложно, долго и дорого</Box>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body2" fontSize="14px">
                                        Много более продвинутых конкурентов
                                    </Typography>
                                </li>
                            </ul>
                            <Typography variant='body2' paragraph>
                                Или у вас уже есть свой собственный сайт, но вы бы хотели продавать ещё больше?
                            </Typography>
                            <Typography variant='body2' paragraph>
                                В обоих случаях вам помогут продажи на маркетплейсе Prom.ua.
                            </Typography>
                            <Typography variant='body1' style={{ fontWeight: 400, paddingTop: 20 }}>
                                В чем преимущества маркетплейса Prom.ua?
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                        19 млн потенциальных клиентов вашего магазина ежемесячно
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                        1,4 млн сделок в прошлом месяце
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                        Интеграция со всеми крупными службами доставки и платёжными системами
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                        Мобильная версия вашего сайта
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                        Экономия на продвижении магазина
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                        Бесплатное обучение основам электронной коммерции и поддержка персонального менеджера
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                        Полномасштабная рекламная кампания на ТВ и в интернете - за привлечение ваших потенциальных клиентов будет платить Prom
                                    </Typography>
                                </ListItem>
                            </List>
                            <Typography variant='body1' style={{ fontWeight: 400, paddingTop: 20 }}>
                                Зарегистрируйтесь сейчас и вы получите:
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                        7 дней бесплатного тестового периода
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <CheckOutlinedIcon color="primary" fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="body2" fontSize="14px">
                                        Premium-дизайн во время тестового периода бесплатно
                                    </Typography>
                                </ListItem>
                            </List>
                            <Typography variant="body1" paragraph style={{ fontWeight: 500, paddingTop: 20 }}>
                                Используйте при регистрации ссылку -
                                <a href={referralLink} target="_blank" rel="noopener noreferrer" style={{ color: '#DB4C19', textDecoration: 'none', marginLeft: 4 }}>
                                    {referralLink}
                                </a>
                                <Tooltip title="Скопировать ссылку">
                                    <IconButton onClick={copyToClipboard} size="small" style={{ marginLeft: 6 }}>
                                        <FileCopyOutlinedIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                и получите 500 ₴ на развитие магазина при покупке платного пакета.
                            </Typography>
                            <Typography variant='body1' paragraph style={{ fontWeight: 400, paddingTop: 20 }}>
                                Остались вопросы?
                            </Typography>
                            <Typography variant="body2" fontSize="14px">
                                <Link href="https://t.me/ceodropshippingua" target="_blank" rel="noopener noreferrer" style={{ color: '#2792E0', textDecoration: 'none', display: 'flex', alignItems: 'center', fontSize: '16px' }}>
                                    <TelegramIcon style={{ marginRight: 4 }} />
                                    Мы в телеграме
                                </Link>
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </Container>
        </Paper>
    );
};

export default SellOnPromUa;
