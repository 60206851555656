import { apiSetRolesPath } from 'config/apiPath'


import instance from './axiosProvider'

export const setRolesToUser = async ({ userId, userRoleIds }) => {
  const res = await instance.post(apiSetRolesPath.replace(':id', userId), { role_ids: userRoleIds })

  return { userRoleIds }
}
