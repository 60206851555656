import React from 'react'

import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from '@material-ui/core'
import ToolTip from '@material-ui/core/Tooltip'

import { addProduct } from 'services/cart/cartService'
import { productViewPath } from 'config/routes'
import {onlyClientOrGreater, onlySupplier} from 'config/roles'

import ToOrderInput from 'components/parts/FormParts/ToOrderInput'
import TooltipInfo from 'components/parts/TooltipInfo'
import { formatPrice } from 'components/parts/FormParts/PriceFormatter';

import BagesMap from './Bages'

const renderColumns = ({
  incoming,
  diplayed,
  throttledChanges,
  tooltipsOpened,
  width,
}) => {
  const {
    products = [],
    config,
    vendors,
    categories = [],
    providers = [],
    classes,
    priceLoading,
  } = incoming
  const isClientOrGreater = onlyClientOrGreater()
  const isSupplier = onlySupplier()
  const {
    max_price = null,
    min_price = null,
    vendor = null,
    category_id = null,
    provider = null,
  } = config

  const bodyWithTooltip = ({
    value,
    row,
    tooltipLabel,
    render,
    placement,
  }) => {
    const { rowIndex } = row
    if (rowIndex === 0) {
      return (
        <>
          <TooltipInfo placement={placement} open={tooltipsOpened} title={tooltipLabel}>
            <div style={{
              height: 50,
              width: 100,
              position: 'absolute',
              display: tooltipsOpened ? 'block' : 'none',
            }}
            />
          </TooltipInfo>
          {render(value, row)}
        </>
      )
    }
    return render(value, row)
  }

  const navigateToProductPage = ({ rowData }) => {
    const { history } = incoming
    history.push(
      productViewPath.replace(':id', rowData[0]),
      {
        status: rowData[12],
        individualPrice: rowData[11],
      },
    )
  }

  const getProductByRow = rowIndex => products[rowIndex]

  const columns = [
    { name: 'id', label: 'id', options: { viewColumns: false, display: diplayed.id, filter: false } },
    // {
    //   name: 'image',
    //   options: {
    //     viewColumns: false,
    //     display: diplayed.image,
    //     filter: false,
    //   },
    // },
    {
      name: 'vendor_code',
      label: 'Артикул',
      options: {
        // display: diplayed.vendor_code,
        viewColumns: false,
        display: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'name',
      label: 'Название',
      options: {
        fixedHeader: false,
        viewColumns: false,
        display: diplayed.name,
        filter: false,
        customBodyRender: (value, row) => (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left', cursor: 'pointer' }}
            onClick={() => navigateToProductPage(row)}
            className={`${classes.hover} ${classes.withImageBreak}`}
          >
            <img
              style={{ display: 'block', margin: '5px 0' }}
              alt='Картинка'
              height='50'
              src={getProductByRow(row.rowIndex).image}
            />
            <div>{value}</div>
          </div>
        ),
      },
    },
    
    {
      name: 'price',
      label: 'РРЦ',
      options: {
        sort: true,
        viewColumns: false,
        display: diplayed.price,
        filterList: max_price ? [max_price] : null,
        filterType: 'custom',
        filterOptions: {
          logic: () => false,
          display: (list, onChange, index, column) => (
            <TextField
              value={list[index][0] || ''}
              label='РРЦ (до)'
              onInput={e => onChange(e.target.value ? [e.target.value] : [], index, column)}
            />
          ),
          fullWidth: width === 'xs',
        },
        customBodyRender: (value, row) => {
        const formattedPrice = formatPrice(value);

        return (
          <div onClick={() => navigateToProductPage(row)}>
            {formattedPrice} ₴
          </div>
        );
      },
      },
    },
    {
      name: 'price_from',
      label: 'РЦЦ',
      options: {
        sort: true,
        viewColumns: false,
        display: false,
        filterList: min_price ? [min_price] : null,
        filterType: 'custom',
        filterOptions: {
          logic: () => false,
          display: (list, onChange, index, column) => (
            <TextField
              value={list[index][0] || ''}
              label='РРЦ (от)'
              onInput={e => onChange(e.target.value ? [e.target.value] : [], index, column)}
            />
          ),
          fullWidth: width === 'xs',
        },
      },
    },
    {
      name: 'price_drop',
      label: 'Дроп-цена',
      options: {
        customBodyRender: (value) => (
      priceLoading ? 
      <CircularProgress size={15} /> : 
      <>
        {formatPrice(value)} ₴
      </>
    ),
        sort: true,
        viewColumns: false,
        display: diplayed.price_drop,
        filter: false,
      },
    },
    {
      name: 'profit',
      label: 'Наценка',
      options: {
        sort: true,
        // viewColumns: false,
        // display: true,
        display: diplayed.profit,
        filter: false,
      },
    },

    {
      name: 'category_name',
      label: 'Категория',
      options: {
        sort: false,
        display: diplayed.category_name,
        filterList: category_id ? [category_id] : null,
        customFilterListOptions: {
          render: v => categories.find(category => category.id === Number(v))?.name,
        },
        customBodyRender: (value, row) => bodyWithTooltip({
          value,
          row,
          tooltipLabel: 'Фильтруйте каталог по бренду или группе товаров',
          render: () => (
            <ToolTip title={`фильтровать по категории ${value}`}>
              <div
                className={classes.hover}
                onClick={() => throttledChanges({
                  category_id: getProductByRow(row.rowIndex).category_id,
                })}
              >
                {value}
              </div>
            </ToolTip>
          ),
        }),
        filterOptions: {
          fullWidth: width === 'xs',
          display: (filterList, onChange, index, column) => (
            <FormControl>
              <InputLabel htmlFor='select-multiple-chip'>
                Категория
              </InputLabel>
              <Select
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  onChange([event.target.value], index, column)
                }}
              >
                {categories.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ),
        },
        filterType: 'custom',
      },
    },

    {
      name: 'vendor_name',
      label: 'Бренд',
      options: {
        sort: false,
        customBodyRender: (value, row) => (
          <ToolTip title={`фильтровать по бренду ${value}`}>
            <div
              className={classes.hover}
              onClick={() => throttledChanges({
                vendor: getProductByRow(row.rowIndex).vendor_name,
              })}
            >
              {value}
            </div>
          </ToolTip>
        ),
        display: diplayed.vendor_name,
        filterList: vendor ? [vendor] : null,
        filterOptions: {
          names: vendors,
          fullWidth: width === 'xs',
        },
      },
    },

    {
      name: 'provider',
      label: 'Поставщик',
      options: {
        sort: false,
        filter: !isSupplier,
        customBodyRender: (value, row) => (
          <ToolTip title={`фильтровать по поставщику ${value}`}>
            <div
              className={classes.hover}
              onClick={() => throttledChanges({
                provider: getProductByRow(row.rowIndex).provider,
              })}
            >
              {value}
            </div>
          </ToolTip>
        ),
        display: diplayed.provider,
        filterList: provider ? [provider] : null,
        filterOptions: {
          names: providers,
          fullWidth: width === 'xs',
        },
      },
    },
    {
      name: 'status',
      label: 'Статус',
      options: {
        sort: true,
        viewColumns: false,
        display: diplayed.status,
        filter: false,
      },
    },
    {
      name: 'available',
      label: 'Наличие',
      options: {
        sort: true,
        viewColumns: false,
        display: diplayed.available,
        customBodyRender: val => (priceLoading ? <CircularProgress size={15} /> : <BagesMap value={val} />),
        filter: false,
      },
    },
    {
      name: 'toOrder',
      label: 'В заказ',
      options: {
        sort: false,
        viewColumns: false,
        display: diplayed.toOrder,
        customBodyRender: (value, row) => bodyWithTooltip({
          value,
          row,
          tooltipLabel: 'Добавляйте товары в заказ',
          render: () => {
            const { rowData } = row
            const notInStock = isClientOrGreater && Number(rowData[12]) === 3
            return (
              <ToOrderInput
                disabled={notInStock}
                buttonColor='primary'
                buttonContent='+'
                onAdd={count => addProduct({ count, product: getProductByRow(row.rowIndex) })}
                />
            )
          },
        }),
        filter: false,
      },
    },
  ]
  if (isClientOrGreater) {
    return columns
  }
  return columns.filter(({ name }) => name !== 'status')
}


export default renderColumns
