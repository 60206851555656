import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import { Box, Typography } from "@material-ui/core";

import { preorderPath } from 'config/routes'
import { calculateCartTotal } from 'services/cart/cartHelpers'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

const CartIndicator = (props) => {
  const { cart, width } = props
  const totalPrice = calculateCartTotal(cart).toFixed(0);
  return cart.length ? (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 5 }}>
        <Box sx={{ width: '100%', m: 1 }}>
          <Button variant='contained' color='primary' component={Link} to={preorderPath}>
            <ShoppingCartOutlinedIcon />
            <Typography variant={width === 'xs' ? 'subtitle2' : 'subtitle2'} align='center' style={{ margin: '0 8px', textTransform: 'lowercase' }}>
          {totalPrice.toLocaleString()} ₴
        </Typography>
          </Button>
        </Box>
         
      </Box>
    </>
  ) : null
}

CartIndicator.defaultProps = {
  cart: [],
}

CartIndicator.propTypes = {
  cart: PropTypes.array,
  width: PropTypes.string.isRequired,
}

export default CartIndicator
