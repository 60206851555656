import * as React from 'react';
import {useState} from 'react';
import { Box, LinearProgress, Typography } from "@material-ui/core";
import { CircularProgress } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import SaveFeedModal from "./SaveFeedModal";
import {
    CATALOG_CART_LIMIT,
    CLEAN_CATALOG_CART,
    REMOVE_FROM_CATALOG_CART
} from "../../../storage/constants/catalogCart.constants";
import {createFeed} from "../../../services/api/feed.service";
import {showSnack} from "../../../storage/actions/snack.actions";
import {feedDetailsPath} from "../../../config/routes";
import {useHistory} from "react-router-dom";
import { store } from "../../../storage";
import Tooltip from '@material-ui/core/Tooltip';
import {onlySupplier} from "../../../config/roles";


export default function CatalogCartIndicator(props) {
    const catalogCart = props.catalogCart;
    const value = catalogCart.qty / CATALOG_CART_LIMIT * 100;

    const [modalOpened, setModalOpened] = useState(false);
    const history = useHistory();

    if (!catalogCart.qty) {
        return null;
    }

    const isSupplier = onlySupplier();
    if (isSupplier) {
        return null;
    }

    function saveFeedClick(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        if (!formData.get('name')) {
            return;
        }

        createFeed({name: formData.get('name'), filter: catalogCart.filters})
            .then((feed) => {
                if (feed.message) {
                    return;
                }
                history.push(feedDetailsPath.replace(':id', feed.id));
                store.dispatch({
                    type: CLEAN_CATALOG_CART,
                })
            })
            .finally(() => {
                setModalOpened(false);
            })
    }

    return (
        <Box sx={{width: '100%'}}>
            <div>
                <SaveFeedModal
                    modalOpened={modalOpened}
                    onSave={saveFeedClick}
                    onClose={() => setModalOpened(false)}
                />
                <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
                    <Box sx={{width: '30%', m: 1, }}>
                        <LinearProgress
                            variant="determinate"
                            value={value}
                            style={{
                                height: 10,
                                borderRadius: 4,
                            }}
                        />                    
                    </Box>
                    <Tooltip
                                title="Создать XML-файл для выгрузки выбранных товаров"
                                arrow
                                placement="bottom"
                            >
                    <Button variant='contained' color='primary' onClick={() => {
                            setModalOpened(true)
                    }}>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            style={{ textTransform: 'none' }}
                        >
                            Выгрузить {catalogCart.qty} товаров
                        </Typography>
                        </Button>
                    </Tooltip>

                </Box>
            </div>
        </Box>
    );
}