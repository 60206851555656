import React, {Component, useEffect, useState} from 'react'
import {Box, CardActions, CardMedia, Link, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {getCategoriesByLevel, getCategoriesWithSub} from "../../../../services/api/category.service";
import _ from "lodash";
import {catalogCategoryProductsViewPath, catalogCategoryViewPath} from "../../../../config/routes";
import {useHistory} from "react-router-dom";
import WorkOutlineOutlined from "@material-ui/icons/WorkOutlineOutlined";
import BrandingWatermarkOutlined from "@material-ui/icons/BrandingWatermarkOutlined";
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import PersonOutlined from "@material-ui/icons/PersonOutlined";
import categoryCardStyles from './CategoryCardStyles';
import { StorefrontOutlined } from '@material-ui/icons';

const CategoryCard = (props) => {
    const category = props?.category
    const history = useHistory();

    return <Grid item md={4} style={categoryCardStyles.gridItem }>
        <Button fullWidth onClick={() => { history.push(catalogCategoryProductsViewPath.replace(':id', category.id)) }}>
            
            <Card style={categoryCardStyles.card}>
                <CardMedia style={categoryCardStyles.cardMedia}
                        component="img"
                        // height="140"
                        image={category.image}
                        alt="image"
                    />
                    <CardContent style={categoryCardStyles.cardContent}>
                        <Typography component="div" style={categoryCardStyles.listItemText}>
                            {category.name}
                        </Typography>
                    </CardContent>
                    <List style={categoryCardStyles.list}>
                        <ListItem style={{ padding: '2px 12px' }}>
                            <ListItemIcon style={categoryCardStyles.listItemIcon}>
                            <WorkOutlineOutlined style={{ fontSize: '20px' }} />
                            </ListItemIcon>
                        <ListItemText style={categoryCardStyles.listItemText}
                            primaryTypographyProps={{ style: { fontSize: 12, lineHeight: 1 } }}
                            primary="Товаров" />
                            <span>{category?.categoryInfo?.products}</span>
                        </ListItem>
                        <ListItem style={{ padding: '2px 12px' }}>
                            <ListItemIcon style={categoryCardStyles.listItemIcon}>
                                <LocalOfferOutlinedIcon style={{ fontSize: '20px' }}/>
                            </ListItemIcon>
                        <ListItemText style={categoryCardStyles.listItemText}
                            primaryTypographyProps={{ style: { fontSize: 12, lineHeight: 1 } }}
                            primary="Брендов" />
                            <span>{category?.categoryInfo?.brands}</span>
                        </ListItem>
                        <ListItem style={{ padding: '2px 12px' }}>
                            <ListItemIcon style={categoryCardStyles.listItemIcon}>
                                <StorefrontOutlined style={{ fontSize: '20px' }}/>
                            </ListItemIcon>
                        <ListItemText style={categoryCardStyles.listItemText}
                            primaryTypographyProps={{ style: { fontSize: 12, lineHeight: 1 } }}
                            primary="Поставщиков" />
                            <span>{category?.categoryInfo?.providers}</span>
                        </ListItem>
                    </List>
                </Card>
            </Button>
        </Grid>;
}

export default CategoryCard
