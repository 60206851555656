import {
  apiDashboardPath
} from 'config/apiPath'

import instance from './axiosProvider'


export const getDashboardData = async () => {
  try {
    const res = await instance.get(apiDashboardPath);
    return res.data;
  } catch (err) {
    throw new Error(err)
  }
}
export const getDashboardForCustomer = async (id) => {
  try {
    const res = await instance.get(apiDashboardPath + '/' + id);
    return res.data;
  } catch (err) {
    throw new Error(err)
  }
}