import {
  apiProviderPath,
  apiProvidersPath,
  apiWithdrawalPath,
  apiWithdrawalsPath,
  apiWithdrawPath
} from 'config/apiPath'

import instance from './axiosProvider'

export const getAllProviders = async () => {
  try {
    const res = await instance.get(apiProvidersPath);
    return res.data;
  } catch (err) {
    throw new Error(err)
  }
}

export const getProvider = async (id) => {
  try {
    const res = await instance.get(apiProviderPath.replace(':id', id));
    return res.data;
  } catch (err) {
    throw new Error(err)
  }
}