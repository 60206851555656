export const styles = theme => ({
  detailsList: {
    [theme.breakpoints.down('xl')]: {
      padding: '20px 160px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '20px 50px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0',
    },
  },
  detail: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px',
    alignItems: 'center',
  },
  odd: {
    backgroundColor: '#141313',
  },
  menuItem: {
    fontSize: '14px',
    '&:hover': {
      color: '#db4c19',
    },
  }
})
