import React, {useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button, TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  amount: {
    marginBottom: theme.spacing(2),
    },
  centerButtons: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const SaveFeedModal = ({ modalOpened, onSave, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby='edit-user-data'
      open={modalOpened}
      onClose={onClose}
      classes={{ paper: classes.root }}
    >
      <form onSubmit={onSave}>
        <DialogTitle style={{ textAlign: 'center' }}>
          Введите название выгрузки
        </DialogTitle>
        <TextField
            inputprops={{ tabIndex: '11' }}
            label='Название'
            variant='outlined'
            fullWidth
            margin='normal'
            name='name'
        />
        <div className={classes.body}>
          <DialogActions className={classes.centerButtons}>
            <Button variant='outlined' color='primary' onClick={onClose}>
              Отмена
            </Button>
            <Button type={"submit"} variant='contained' color='secondary'>
              Сохранить
            </Button>
          </DialogActions>
        </div>
      </form>
    </Dialog>
  );
};

export default SaveFeedModal;