import React, { memo } from 'react'
import PropTypes from 'prop-types'

import DataTable from 'mui-datatables'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'


import Clear from '@material-ui/icons/Clear'
import DeleteForever from '@material-ui/icons/DeleteForever'

import { setCount, clearCart, removeFromCart } from 'services/cart/cartService'
import { calculateCartTotal } from 'services/cart/cartHelpers'
import { useHistory } from 'react-router-dom'
import { productViewPath } from 'config/routes'
import { formatPrice } from 'components/parts/FormParts/PriceFormatter';
import { textLabels } from 'config/tableConfig/textLabels'

// const themePaperWithoutShadow = theme => createTheme({
//   overrides: {
//     ...theme.overrides,
//     MuiPaper: {
//       root: {
//         boxShadow: 'unset',
//       },
//       elevation4: {
//         boxShadow: 'unset',
//       },
//     },
//   },
// })


const Table = memo(({
  cart,
  classes,
}) => {
  const history = useHistory(); 

  const options = {
    download: false,
    print: false,
    search: false,
    filter: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRows: 'none',
    pagination: false,
    textLabels,
  }

  // const cartData = cart.map(item => ({
  //   ...item.product,
  //   count: item.count,
  //   total: (
  //     typeof item.product.price_drop === 'string'
  //       ? Number(item.product.price_drop.replace(',', ''))
  //       : item.product.price_drop
  //       * item.count
  //   ).toLocaleString(),
  // }))
  const cartData = cart.map(item => ({
  ...item.product,
  count: item.count,
  total: (
    typeof item.product.price_drop === 'string'
      ? parseFloat(item.product.price_drop.replace(/[^\d.-]/g, '')) * item.count
      : item.product.price_drop * item.count
    ).toFixed(0),
    price_drop_formatted: formatPrice(item.product.price_drop) + ' ₴',
  total_formatted: formatPrice( 
    typeof item.product.price_drop === 'string'
      ? parseFloat(item.product.price_drop.replace(/[^\d.-]/g, '')) * item.count
      : item.product.price_drop * item.count
  ) + ' ₴',
}));
  const getProductByRowMeta = (meta) => {
    const { rowData } = meta
    const id = rowData[0]
    return cart.find(item => item.product.id === id).product
  }

  const columns = [
    { name: 'id', label: '', options: { display: false } },
    // {
    //   name: 'image',
    //   label: ' ',
    //   options: {
    //     customBodyRender: value => <img alt='Картинка' height='50' src={value} />,
    //   },
    // },
    {
    name: 'name',
    label: 'Название',
    // options: {
    //   customBodyRender: (value, meta) => (
    //     <div
    //       style={{ cursor: 'pointer' }}
    //       onClick={() => {
    //         const product = getProductByRowMeta(meta);
    //         if (product) {
    //           history.push(productViewPath.replace(':id', product.id));
    //         }
    //       }}
    //     >
    //       {value}
    //     </div>
    //   ),
      // },
    options: {
    customBodyRender: (value, meta) => {
      const product = getProductByRowMeta(meta);

      return (
        <div style={{ textAlign: 'left', cursor: 'pointer' }} onClick={() => { 
          if (product) {
            history.push(productViewPath.replace(':id', product.id));
          }
        }}>
          <img alt='Картинка' height='50' src={product.image} style={{ display: 'block', margin: '5px 0' }} />
          <div>{product.name}</div>
        </div>
      );
    },
  },
  },

    {
      name: 'count',
      label: 'Количество',
      options: {
        customBodyRender: (value, meta) => (
          <TextField
            defaultValue={value}
            type='number'
            variant='outlined'
            inputProps={{
              min: 1,
              step: 1,
            }}
            onChange={(e) => {
              const { value } = e.target
              const product = getProductByRowMeta(meta)
              setCount({ count: +value, product })
            }}
          />
        ),
      },
    },
    { name: 'price_drop_formatted', label: 'Дроп-цена' },
    { name: 'total_formatted', label: 'Сумма' },
    {
      name: 'delete',
      label: ' ',
      options: {
        customBodyRender: (_row, meta) => {
          const product = getProductByRowMeta(meta)
          return (
            <IconButton onClick={() => { removeFromCart(product.id) }}>
              <DeleteForever />
            </IconButton>
          )
        },

      },
    },
  ]
  
  return (
    <Paper>
      {/* <MuiThemeProvider > */}
        <DataTable
          columns={columns}
          data={cartData}
          title='Список товаров в заказе'
          options={{ ...options }}
        />
      {/* </MuiThemeProvider> */}
      <Typography
        variant='subtitle1'
        align='right'
        paragraph
        style={{ marginRight: 18 }}
      >
        <IconButton onClick={clearCart}>
          <Clear />
        </IconButton>
      Удалить все товары из заказа
      </Typography>
      <Grid container style={{ padding: '6px 18px' }}>
        <Grid item xs={12}>
          <Typography variant='h6' align='right'>
            <span className={classes.Attributes}>
              Общая сумма заказа:
              <span className={classes.value}>
                {calculateCartTotal(cart).toFixed(0) + ' ₴'}
              </span>
            </span>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
})

Table.defaultProps = {
  cart: [],
  classes: {},
}

Table.propTypes = {
  cart: PropTypes.array,
  classes: PropTypes.object,
}

export default Table
