export const calculateCartTotal = cart => cart.reduce(
  (prev, current) => {
    const { count, product } = current
    const { price_drop } = product
    if (typeof price_drop === 'number') {
      return prev + Number(count) * price_drop
    }
    if (typeof price_drop === 'string') {
      return prev + Number(count) * Number(price_drop.replace(',', ''))
    }
    return prev
  },
  0,
)
