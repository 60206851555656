import {
    CATALOG_CART_LIMIT,
    CLEAN_CATALOG_CART,
    REMOVE_FROM_CATALOG_CART,
    UPDATE_CATALOG_CART
} from "../constants/catalogCart.constants";
import {getStorageItem, setStorageItem} from "../../services/storage";

const initialState = JSON.parse(getStorageItem('CATALOG_CART')) ||
    {
        qty: 0,
        filters: {}
    };

export default function updateCatalogCart(state = initialState, action) {
    if (action.type === UPDATE_CATALOG_CART) {

        const filters = {...state.filters, ...action.payload.filters};
        const result = {
            qty: calcQty(filters),
            filters: filters,
        };
        setStorageItem('CATALOG_CART', JSON.stringify(result))
        return result;
    }
    if (action.type === REMOVE_FROM_CATALOG_CART) {
        delete state.filters[action.payload.filterId];
        const result = {
            qty: calcQty(state.filters),
            filters: state.filters,
        };
        setStorageItem('CATALOG_CART', JSON.stringify(result))
        return result;
    }
    if (action.type === CLEAN_CATALOG_CART) {
        const result = {
            qty: 0,
            filters: {}
        };
        setStorageItem('CATALOG_CART', JSON.stringify(result))
        return result;
    }
    return state
}

function calcQty(filters) {
    const filtersArray = Object.values(filters);
    const value = filtersArray.reduce(function (sum, filter) {
        return sum + filter.count;
    }, 0);

    if (value > CATALOG_CART_LIMIT) {
        throw new Error('Лимит продуктов достигнут');
    }

    return value;
}
