import React, {useEffect, useState} from 'react'
import Paper from '@material-ui/core/Paper'
import DataTable from 'mui-datatables'
import {getAllWithdrawals, getWithdrawal, updateWithdrawal} from "../../../services/api/withdrawal.service";
import {useHistory} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {Box, CardMedia, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select} from "@material-ui/core";
import {onlyAdminOrGreater} from "../../../config/roles";
import Button from "@material-ui/core/Button";
import Table from "../OrderDetails/Table";
import classNames from "classnames";
import {updateOrder} from "../../../services/api/order.service";
import {showSnack} from "../../../storage/actions/snack.actions";
import {getProvider} from "../../../services/api/provider.service";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {WorkOutlineOutlined, AddShoppingCartOutlined, EventAvailableOutlined, MonetizationOnOutlined} from "@material-ui/icons";
import _ from "lodash";
import {useSelector} from "react-redux";
import {store} from "../../../storage";
import {REMOVE_FROM_CATALOG_CART, UPDATE_CATALOG_CART} from "../../../storage/constants/catalogCart.constants";
import { newOrderPath, productsPath, productsProviderPath } from "../../../config/routes";
import Tooltip from '@material-ui/core/Tooltip';


const ProviderDetails = (props) => {
    const id = props?.match?.params?.id
    const classes = props?.classes
    const history = useHistory()

    const [provider, setProvider] = useState([]);

    useEffect(() => {
        getProvider(id).then((data) => {
            setProvider(data);
        })
    }, []);

    const catalogCart = useSelector((state) => state.catalogCart) || {};
    const filterId = 'provider_' + id;
    const isInCatalogCart = catalogCart.filters[filterId];

    const addToCatalogCart = () => {
        if (isInCatalogCart) {
            store.dispatch({
                type: REMOVE_FROM_CATALOG_CART,
                payload: {filterId},
            })
            return;
        }
        if (provider?.productCount < 1) {
            showSnack({
                variant: 'error',
                message: 'Нечего добавлять',
            });
            return;
        }
        try {
            store.dispatch({
                type: UPDATE_CATALOG_CART,
                payload: {filters: {[filterId]: {count: provider?.productCount, provider_id: provider?.provider}}},
            })
            showSnack({
                variant: 'success',
                message: `${provider?.productCount} товаров добавлено в выгрузку`,
            })
        } catch (e) {
            showSnack({
                variant: 'error',
                message: e.message,
            });
        }
    }

    return (
        <Grid container>
            <Grid item md={3} style={{display: 'flex', background: '#262626', justifyContent: 'center', alignItems: "center", borderRadius: '4px'}}>
                <Card fullWidth>
                    <CardMedia
                        component="img"
                        height="240px"
                        image={provider?.image}
                        alt="image"
                    />
                </Card>
            </Grid>
            <Grid item md={6} style={{textAlign: 'center', color: 'white', background: '#262626', padding: '15px', borderRadius: '4px'}}>
                <Typography variant="h6" component="div">
                    {provider?.name}
                </Typography>
                <List>
                    <a href="" onClick={() => { history.push(productsProviderPath.replace(':provider', provider?.id)) }} style={{color: '#DB4C19'}}>
                    <ListItem disablePadding dense>
                            <ListItemIcon>
                                <WorkOutlineOutlined style={{color: '#DB4C19'}}/>
                            </ListItemIcon>
                            <ListItemText primary="Товаров"/>
                            <span>{provider?.productCount}</span>
                        </ListItem></a>
                    <ListItem disablePadding dense>
                        <ListItemIcon>
                            <MonetizationOnOutlined style={{color: '#DB4C19'}}/>
                        </ListItemIcon>
                        <ListItemText primary="Профит"/>
                        <span>{_.round(provider?.profit).toFixed(0)}%</span>
                    </ListItem>
                    <ListItem disablePadding dense>
                        <ListItemIcon>
                            <AddShoppingCartOutlined style={{color: '#DB4C19'}}/>
                        </ListItemIcon>
                        <ListItemText primary="Заказов"/>
                        <span>{provider?.orderCount}</span>
                    </ListItem>
                    <ListItem disablePadding dense>
                        <ListItemIcon>
                            <EventAvailableOutlined style={{color: '#DB4C19'}}/>
                        </ListItemIcon>
                        <ListItemText primary="Прайс обновлен"/>
                        <span>{provider?.last_update_date}</span>
                    </ListItem>
                </List>
                <Tooltip
                        title={isInCatalogCart ? "Удаляйте товары из выгрузки" : "Добавляйте выбранные товары в выгрузку"}
                        arrow
                        placement="right"
                    >
                
                <Button onClick={addToCatalogCart} variant='contained' color='primary' >
                    {isInCatalogCart ? 'Удалить из выгрузки' : 'Добавить в выгрузку'}
                </Button>
                </Tooltip>
            </Grid>
        </Grid>
    )
}

export default ProviderDetails
