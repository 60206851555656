import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'

import SignUp from './SignUp'
import { styles } from './SignUp.styles'
import {connect} from "react-redux";
import {googleLogin, signin} from "../../../../../services/api/login.service";
import {error} from "../../../../../storage/selectors/loginData.selectors";

const mapDispatchToProps = dispatch => ({
    googleLogin: data => dispatch(googleLogin(data)),
})

const mapStateToProps = state => ({
    error: error(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(SignUp)))

