import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  Paper,
} from '@material-ui/core'

import DataTable from 'mui-datatables'
import debounce from 'lodash/debounce'
import { textLabels } from 'config/tableConfig/textLabels'
import { checkAccessByLevel } from 'config/roles'
import SearchInput from 'components/parts/SearchInput'

import renderColumns from './renderColumns'
import {store} from "../../../../storage";
import {REMOVE_FROM_CATALOG_CART} from "../../../../storage/constants/catalogCart.constants";
import {SET_PRODUCTS} from "../../../../storage/constants/products.constants";

const MemoizedTableRender = React.memo(props => (
  <Paper>
    <DataTable
      {...props}
    />
  </Paper>
))

class Products extends PureComponent {
  state = {
    diplayed: {
      id: false,
      image: false,
      name: true,
      category_name: false,
      provider: false,
      vendor_name: false,
      vendor_code: true,
      barcode: false,
      volume: false,
      weight: false,
      uktz: false,
      price: true,
      price_drop: checkAccessByLevel(2),
      status: checkAccessByLevel(3),
      available: true,
      toOrder: true,
    },
  }

  throttledChanges = debounce((value) => {
    if (this.props.category) {
      value.category_id = this.props.category.id;
    }
    if (this.props.feedId) {
      value.feed_id = this.props.feedId;
    }
    const { config } = this.props
    const { getProductList } = this.props
    const { count, page } = config
    if (count) delete config.count
    if (page) delete config.page
    getProductList({ ...config, ...value })
  }, 10)


  componentDidMount() {
    const { location, provider } = this.props
    const { search } = location
    const paramsEntries = search.slice(1).split('&').map(item => item.split('='))
    const paramsMap = Object.fromEntries(paramsEntries)
    const { category_id } = paramsMap;
    this.throttledChanges({
      page: 1,
      limit: 25,
      category_id,
      provider : provider ? provider.name : null,
      search_text: '',
      price: null,
      price_from: null,
      vendor: null,
    })
  }

  componentWillUnmount() {
    store.dispatch({
      type: SET_PRODUCTS,
      payload: {},
    })
  }

  onTableChange = (eventType, state) => {
    if (eventType === 'viewColumnsChange') {
      const diplayed = Object.fromEntries(state.columns.map(col => [col?.name, col?.display]))
      this.setState({ diplayed })
    }
    if (['changeRowsPerPage', 'changePage'].indexOf(eventType) > -1) {
      const { config } = this.props
      const {
        filterList,
        page,
        rowsPerPage,
      } = state

      // if (eventType === 'search' && searchText && searchText.length < 3) return

      const price = filterList[3][0]
      const price_from = filterList[4][0]
      const vendor = filterList[8][0]
      const provider = filterList[9][0]
      const category_id = filterList[7]
      const dependenciesKeys = [
        'page',
        'limit',
        'count',
        'price',
        'price_from',
        // 'searchText',
        'vendor',
        'provider',
        'category_id',
      ]
      const mapconfigToState = (key) => {
        switch (key) {
          case 'limit':
            return state.rowsPerPage
          default:
            return state[key]
        }
      }


      if (dependenciesKeys.find(key => config[key] !== mapconfigToState(key))) {
        this.throttledChanges({
          page: page + 1,
          limit: rowsPerPage,
          price,
          price_from,
          vendor,
          provider,
          category_id,
        })
      }
    }
  }

  onFilterChange = (changedColumn, filterList, type, changedColumnIndex) => {
    if (type === 'reset') {
      this.throttledChanges({
        page: 1,
        limit: 25,
        category_id: null,
        search_text: '',
        price: null,
        price_from: null,
        vendor: null,
        provider: null,
      })
      return;
    }
    const filterFieldsMap = {
      category_name: 'category_id',
      vendor_name: 'vendor',
      provider: 'provider',
      price: 'max_price',
      price_from: 'min_price',
    }
    this.throttledChanges({ [filterFieldsMap[changedColumn]]: filterList[changedColumnIndex][0] })
  }

  onColumnSortChange = (changedColumn, direction) => {
    const fieldsMap = {
      available: 'available_id',
      price: 'price',
      price_drop: 'price_drop',
      profit: 'profit',
      status: 'status',
      name: 'name',
    };
    this.throttledChanges({ sort: `${fieldsMap[changedColumn]}=${direction}` })
  }

  onSearch = (value) => {
    this.throttledChanges({
      search_text: value,
    })
  }

  render() {
    const { onTableChange, onFilterChange, onColumnSortChange } = this
    const {
      products = [],
      config,
      newOrder,
      width,
    } = this.props
    const {
      page,
      limit,
      count,
    } = config


    const { diplayed } = this.state

    const columns = renderColumns({
      diplayed,
      incoming: this.props,
      throttledChanges: this.throttledChanges,
      tooltipsOpened: newOrder,
      width,
    })


    const options = {
      download: false,
      print: false,
      serverSide: true,
      count,
      page,
      selectableRowsHeader: false,
      selectableRows: 'none',
      rowsPerPage: limit,
      rowsPerPageOptions: [10, 25, 50, 100],
      onTableChange,
      onFilterChange,
      onColumnSortChange,
      textLabels,
      search: false,
      setFilterChipProps: (colIndex, colName, data) => {
        let label = '';
        if (colName === 'price' && data) {
          label = 'РРЦ до: ' + data;
        }
        if (colName === 'price_from' && data) {
          label = 'РРЦ от: ' + data;
        }
        const newProps = label ? {label} : {};
        return {
          ...newProps
        }
      }
    }

    return (
      <DataTable
        columns={columns}
        data={products}
        title={<SearchInput tooltipsOpened={newOrder} tooltipTitle='Ищите товары в каталоге по артикулу или названию' onSearch={this.onSearch} placeholder={'Код товара, название товара'}/>}
        options={{
          ...options,
        }}
      />
    )
  }
}

Products.defaultProps = {
  products: [],
  // searchAutocomleteList: [],
  config: {},
  // vendors: [],  // vendors: PropTypes.array,
  newOrder: false,
  width: '',
}

Products.propTypes = {
  getProductList: PropTypes.func.isRequired,
  // getSearchAutocomplete: PropTypes.func.isRequired,
  products: PropTypes.array,
  config: PropTypes.object,
  // vendors: PropTypes.array,
  location: PropTypes.object.isRequired,
  newOrder: PropTypes.bool,
  width: PropTypes.string,
}

export default Products
