import {
  apiFeedPath,
  apiFeedsPath,
} from 'config/apiPath'

import instance from './axiosProvider'

export const getAllFeeds = async () => {
  try {
    const res = await instance.get(apiFeedsPath);
    return res.data;
  } catch (err) {
    throw new Error(err)
  }
}

export const getFeed = async (id) => {
  try {
    const res = await instance.get(apiFeedPath.replace(':id', id));
    return res.data;
  } catch (err) {
    throw new Error(err)
  }
}

export const createFeed = async (data) => {
  try {
    const res = await instance.post(apiFeedsPath, data);
    return res.data;
  } catch (err) {
    throw new Error(err)
  }
}

export const deleteFeed = async (id) => {
  try {
    const res = await instance.delete(apiFeedPath.replace(':id', id));
    return res.data;
  } catch (err) {
    throw new Error(err)
  }
}