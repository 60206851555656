import React, { Component } from 'react'
import { object } from 'prop-types'
// import withStyles from '@material-ui/core/styles/withStyles';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  TextField,
  DialogTitle,

} from '@material-ui/core'

import { updateProfile } from 'services/api/profile.service'

import MaskedPhone from 'components/assets/MaskedPhone'
import {showSnack} from "../../../../storage/actions/snack.actions";
import {onlySupplier} from "../../../../config/roles";


class UserEdit extends Component {
  static propTypes = {
    classes: object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      ...props.user,
    }
  }

  handleClose = () => {
    const { onClose } = this.props
    onClose()
  };

  handleInput = (value, field) => {
    this.setState((currentState) => {
      currentState[field] = value
      return currentState
    })
  }

  handleOk = () => {
    const userData = { ...this.state }
    if (typeof userData.customerPhone === 'string') {
      userData.customerPhone = userData.customerPhone.replace(/\D+/g, '')
    }
    if (typeof userData.companyPhone === 'string') {
      userData.companyPhone = userData.companyPhone.replace(/\D+/g, '')
    }
    if (userData.customerCard && userData.customerCard.length !== 16) {
      showSnack({
        variant: 'error',
        message: `Номер карты должен состоять из 16 цифр`,
      })
      return;
    }
    if (userData.iban && userData.iban.length < 29) {
      showSnack({
        variant: 'error',
        message: `Минимум 29 цифр и букв`,
      })
      return;
    }
    updateProfile(userData).then((resp) => {
      if (resp) {
        const { onOk } = this.props
        onOk()
        this.handleClose()
      }
    }).catch((err) => {
      console.log(err.message)
    })
  }

  render() {
    const { classes, open } = this.props
    const {
      city = '',
      houseNumber = '',
      street = '',
      officeNumber = '',
      customerPhone = '',
      telegram = '',
      customerPosition = '',
      customerWebsite = '',
      customerLastname = '',
      customerName = '',
      customerCard = '',
      companyName = '',
      companyPhone = '',
      companyPost = '',
      iban = '',
    } = this.state
    console.log(customerPhone)
    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby='edit-user-data'
        open={open}
        classes={{ paper: classes.root }}
      >
        <DialogTitle
          style={{ textAlign: 'center' }}
        >
          Изменить личные данные
        </DialogTitle>
        <div className={classes.body}>
          <form>
            <TextField
              inputprops={{ tabIndex: '11' }}
              onInput={(e) => { this.handleInput(e.target.value, 'customerName') }}
              value={customerName || ''}
              label='Имя'
              variant='outlined'
              fullWidth
              margin='normal'
            />
            <TextField
              inputprops={{ tabIndex: '12' }}
              onInput={(e) => { this.handleInput(e.target.value, 'customerLastname') }}
              value={customerLastname || ''}
              label='Фамилия'
              variant='outlined'
              fullWidth
              margin='normal'
            />
            {/* <TextField
              inputprops={{ tabIndex: '13' }}
              onInput={(e) => { this.handleInput(e.target.value, 'customerPosition') }}
              value={customerPosition || ''}
              label='Должность'
              variant='outlined'
              fullWidth
              margin='normal'
            /> */}

            <TextField
              inputprops={{ tabIndex: '18' }}
              onInput={(e) => { this.handleInput(e.target.value, 'customerWebsite') }}
              value={customerWebsite || ''}
              label='Сайт'
              variant='outlined'
              fullWidth
              margin='normal'
            />
            <TextField
              onChange={(e) => { this.handleInput(e.target.value, 'customerPhone') }}
              value={customerPhone || '(0'}
              variant='outlined'
              fullWidth
              margin='normal'
              placeholder='Телефон'
              label='Телефон'
              InputProps={{
                tabIndex: '19',
                inputComponent: MaskedPhone,
              }}
              helperText='Номер в формате (0xx)xxx-xx-xx'
            />
            <TextField
              onChange={(e) => { this.handleInput(e.target.value, 'telegram') }}
              value={telegram || ''}
              variant='outlined'
              fullWidth
              margin='normal'
              label='Телеграм'
              InputProps={{
                tabIndex: '19',
              }}
            />
            <TextField
              onChange={(e) => { this.handleInput(e.target.value.replace(/\D/,''), 'customerCard') }}
              value={customerCard || ''}
              variant='outlined'
              fullWidth
              margin='normal'
              placeholder='Карта для выплат'
              label='Карта для выплат'
              InputProps={{
                tabIndex: '20'
              }}
              helperText='16 цифр'
            />
            <TextField
              onChange={(e) => { this.handleInput(e.target.value, 'iban') }}
              value={iban || ''}
              variant='outlined'
              fullWidth
              margin='normal'
              placeholder='Номера счета в формате IBAN'
              label='Номера счета в формате IBAN'
              InputProps={{
                tabIndex: '20'
              }}
              helperText='Минимум 29 букв и цифр'
            />
            {onlySupplier() && (<TextField
                onChange={(e) => { this.handleInput(e.target.value, 'companyName') }}
                value={companyName || ''}
                variant='outlined'
                fullWidth
                margin='normal'
                placeholder='Название компании'
                label='Название компании'
                InputProps={{
                  tabIndex: '20'
                }}
            />)}
            {onlySupplier() && (<TextField
                onChange={(e) => { this.handleInput(e.target.value, 'companyPost') }}
                value={companyPost || ''}
                variant='outlined'
                fullWidth
                margin='normal'
                placeholder='Почта для заказов'
                label='Почта для заказов'
                InputProps={{
                  tabIndex: '20'
                }}
            />)}
            {onlySupplier() && (<TextField
                onChange={(e) => { this.handleInput(e.target.value, 'companyPhone') }}
                value={companyPhone || '(0'}
                variant='outlined'
                fullWidth
                margin='normal'
                placeholder='Телефон для заказов'
                label='Телефон для заказов'
                InputProps={{
                  tabIndex: '19',
                  inputComponent: MaskedPhone,
                }}
                helperText='Номер в формате (0xx)xxx-xx-xx'
            />)}
            <DialogActions>
              <Button
                variant='outlined'
                color='primary'
                onClick={this.handleClose}
              >
                закрыть
              </Button>
              <Button
                variant='contained'
                color='secondary'
                onClick={this.handleOk}
              >
                применить
              </Button>
            </DialogActions>
          </form>
        </div>
      </Dialog>
    )
  }
}

export default UserEdit
