/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react'
import PropTypes, { object, string } from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import MinimalTabs from 'components/parts/tabs/MinimalTabs'
import MinimalTab from 'components/parts/tabs/MinimalTab'
import Avatar from '@material-ui/core//Avatar'
import RoomOutlined from '@material-ui/icons/RoomOutlined'
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined'
import CardTravelOutlinedIcon from '@material-ui/icons/CardTravelOutlined'
import GradeOutlinedIcon from '@material-ui/icons/GradeOutlined'

import UserEdit from './UserEdit'
import {onlyAdminOrGreater, onlyClientOrGreater, onlySupplier} from "../../../config/roles";
import {CloudUpload, ReportOutlined} from "@material-ui/icons";
import {styled} from "@material-ui/core";
import {showSnack} from "../../../storage/actions/snack.actions";
import {uploadImportFile} from "../../../services/api/products.service";
import {getCustomer, uploadAvatar} from "../../../services/api/user.service";
import {IMAGES_STORAGE_BASE_URL} from "../../../config/constants";
import {useParams} from "react-router-dom";
import EarningsCard from "../Dashboard/EarningsCard";
import _ from "lodash";
import {getDashboardData, getDashboardForCustomer} from "../../../services/api/dashboard.service";

const detailsLabels = {
  customerName: 'Имя',
  customerLastname: 'Фамилия',
  // customerPosition: 'Должность',
  customerWebsite: 'Сайт/Инста/Тикток',
  customerPhone: 'Телефон',
  customerEmail: 'Email',
  telegram: 'Telegram',
  customerCard: 'Карта для выплат',
  iban: 'Номера счета в формате IBAN',
}

const Item = (props) => {
  const { value, name, field } = props;
  const needToFill = false;
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography  style={{ fontSize: '14px', display: 'flex', alignItems: 'center', padding: '5px 0'}}>
          {`${name}:`}
          {needToFill ? <ReportOutlined fontSize="medium" style={{ color: 'red', marginLeft: '5px' }}/> : ''}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography style={{ fontSize: '14px', display: 'flex', alignItems: 'center', padding: '5px 0' }}>
          {value}
        </Typography>
        
      </Grid>
    </Grid>
  )
}

Item.defaultProps = {
  value: '',
  name: '',
}

Item.propTypes = {
  value: string,
  name: string,
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const UserView = ({
  classes,
  customer,
  getProfile,
  logout,
}) => {
  const { id } = useParams();
  const [dashboardData, setDashboardData] = useState({});

  const [adminCustomer, setAdminCustomer] = useState(null);
  if (adminCustomer && id) {
    customer = adminCustomer;
  }

  const [newAvatarName, setNewAvatarName] = useState(null)


  const handleFileChange = (e) => {
    var file = e.target.files[0];

    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    uploadAvatar(formData).then((data) => {
      if (data.name) {
        setNewAvatarName(data.name);
      }
    })
  };

  useEffect(() => {
    getProfile();
    if (id) {
      getCustomer(id).then((data) => {
        setAdminCustomer(data);
      });
      getDashboardForCustomer(id).then((data) => {
        setDashboardData(data);
      })
    }
  }, [getProfile, id])

  const [popupOpened, setPopupOpened] = useState(false)
  const [tab, setTab] = useState(0)

  const {
    city = '',
    houseNumber = '',
    street = '',
    officeNumber = '',
    customerName,
    customerLastname,
  } = customer

  var avatar = newAvatarName || customer.avatar;

    const avatarUrl = avatar ? IMAGES_STORAGE_BASE_URL + '/avatars/' + customer?.idCustomer + '/' + avatar : '';

  const address = []
  street && address.push(street)
  houseNumber && address.push(houseNumber)
  officeNumber && address.push(officeNumber)

  if (onlySupplier()) {
    detailsLabels['companyName'] = 'Название компании';
    detailsLabels['companyPost'] = 'Почта для заказов';
    detailsLabels['companyPhone'] = 'Телефон для заказов ';
  }

  return (
    <Paper>
      {popupOpened && (
        <UserEdit
          open={popupOpened}
          onClose={() => setPopupOpened(false)}
          onOk={() => getProfile()}
          user={customer}
        />
      )}
      <div className={classes.header}>
        <div className={classes.avatarBlock}>
          <form>
            <label style={{cursor: 'pointer'}}>
            <Avatar src={avatarUrl} className={classes.avatar} />
                <VisuallyHiddenInput type="file" name="file" onChange={handleFileChange} />
            </label>
          </form>
          <div>
            <Typography variant='h5'>{customerName} {customerLastname}</Typography>
            <Typography variant='h7'>ID профиля: {customer.idCustomer}</Typography>
            {/* <div className={classes.statusRow}>
              <span className={classes.statusWithIcon}><RoomOutlined />{city}</span>
              <span className={classes.statusWithIcon}><DateRangeOutlinedIcon />12.04.2018</span>
            </div>
            <div className={classes.statusRow}>
              <span className={classes.statusWithIcon}><CardTravelOutlinedIcon />Партнер</span>
              <span className={classes.statusWithIcon}><GradeOutlinedIcon />Статус Vip</span>
            </div> */}
          </div>
        </div>
        <div className={classes.logoutContainer} >
          {!!adminCustomer ||
              <Button
                  size='small'
                  color='primary'
                  variant='outlined'
                  onClick={() => logout()}
              >
                Выйти из профиля
              </Button>
          }
        </div>
      </div>
      <div>
        <MinimalTabs value={tab} onChange={(_e, tab) => setTab(tab)}>
          <MinimalTab label='Общее'></MinimalTab>
          {onlySupplier() && (<MinimalTab label='Платежная информация'></MinimalTab>)}
          {onlySupplier() && (<MinimalTab label='Отправка Заказов'></MinimalTab>)}
          {id && onlyAdminOrGreater() && (<MinimalTab label='Дроп Баланс'></MinimalTab>)}
        </MinimalTabs>
      </div>
      {tab === 0 && (
        <Grid container spacing={4} style={{ padding: 16 }}>
          <Grid item xs={12} md={12} lg={8}>
            <Paper elevation={0}>
              <div className='padded'>
                <div className={classes.headreWuthBtn}>
                  <Typography
                    component='div'
                    variant='h6'
                    paragraph
                  >
                    ОСНОВНАЯ ИНФОРМАЦИЯ
                  </Typography>
                  {!!adminCustomer ||
                      <Button
                          color='primary'
                          variant='contained'
                          onClick={() => setPopupOpened(true)}
                          size='small'
                          style={{ height: 31, padding: '6px 16px' }}
                      >
                        Изменить
                      </Button>
                  }

                </div>
                {Object.keys(detailsLabels).map(key => (
                  <Item
                    key={key}
                    field={key}
                    name={detailsLabels[key]}
                    value={(key === 'address') ? address.join(', ') : customer[key]}
                  />
                ))}
              </div>
            </Paper>
          </Grid>
        </Grid>
      )}
      {id && onlyAdminOrGreater() && tab === 1 && (
          <Grid container spacing={4} style={{ padding: 16 }}>
            <Grid item xs={12} md={12} lg={8}>
              <Paper elevation={0}>
                <div className='padded'>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <Typography
                        color='textPrimary'
                        variant='caption'
                        style={{ fontSize: 24, textTransform: 'uppercase' }}
                    >
                      {"ДОХОДЫ"}
                    </Typography>
                    <Typography
                        color='textSecondary'
                        variant='caption'
                        style={{ fontSize: 12 }}
                    >
                    </Typography>
                  </div>
                  {/* </Tooltip> */}
                  <Typography style={{ display: 'flex', flexDirection: 'column', gap: '10px', margin: '20px 0' }}>
                    <EarningsCard
                        amount={_.round(dashboardData?.earnings?. available, 2).toFixed(2)}
                        label={ "Дроп баланс"}
                        status="available" />
                    <EarningsCard
                        amount={_.round(dashboardData?.earnings?.pending, 2).toFixed(2)}
                        label="Ожидаемый доход"
                        status="pending" />
                    <EarningsCard
                        amount={_.round(dashboardData?.earnings?.expenses, 2).toFixed(2)}
                        label="Затраты"
                        status="expenses" />
                  </Typography>
                </div>
              </Paper>
            </Grid>
          </Grid>
      )}
    </Paper>
  )
}

UserView.defaultProps = {
  customer: {},
}

UserView.propTypes = {
  classes: object.isRequired,
  customer: object,
  getProfile: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
}

export default UserView
