import { connect } from 'react-redux'

import {
  open,
  userId,
  userListInfo,
  userRoles,
} from 'storage/selectors/userRoleDialog.selectors'
import { closeUserRoleDialog } from 'storage/actions/userRoleDialog.actions'


import RoleDialog from './RoleDialog'

const mapStateToProps = state => ({
  open: open(state),
  userId: userId(state),
  userRoles: userRoles(state),
  userData: userListInfo(state),
})

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(closeUserRoleDialog()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RoleDialog)
