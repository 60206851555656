import React, {Component, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
  Paper,
  Grid,
  Typography,
  Chip, ImageList, ImageListItem, Box, 
} from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import Error from '@material-ui/icons/Error'
import Cancel from '@material-ui/icons/Cancel'

import { productsPath } from 'config/routes'
import ToOrderInput from 'components/parts/FormParts/ToOrderInput'
import { addProduct } from 'services/cart/cartService'
import { onlyClientOrGreater } from 'config/roles'
import CatalogView from "../CatalogView/CatalogView";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { getCategoriesWithSub } from "../../../../services/api/category.service";
import { formatPrice } from 'components/parts/FormParts/PriceFormatter';
import Tooltip from '@material-ui/core/Tooltip';


const ProductView = (props) => {
  const isClientOrGreater = onlyClientOrGreater()
  const { classes, product = {}, location, match } = props
  const { state = {} } = location
  const { status, individualPrice } = state
  const { data = {} } = product
  const {
    vendor_code,
    vendor_name,
    category_name,
    image,
    images,
    name,
    price,
    price_drop,
    category_id,
    description,
    params = []
  } = data;

  const [selectedImage, setSelectedImage] = useState();
  const [value, setValue] = useState(0);

  useEffect(() => {
    props.getData(match.params.id)
  }, [match.params.id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
          {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
          )}
        </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
      <Paper className={classes.ProductPage}>
        <Typography variant='h5' style={{ padding: '15px 0'}}>
          {name}
        </Typography>
        <Grid container spacing={3}>
          <Grid item sm={12} md={4}>
            <div className={classes.imageBlock}>
              <img alt='Здесь должна была быть картинка' src={selectedImage || image} />
            </div>
            <ImageList sx={{ width: 500, height: 500 }} cols={3} rowHeight="auto">
              {images?.map((item) => (
                  <ImageListItem key={item.id} className={classes.imageListItem}>
                    <img
                        style={{cursor: 'pointer'}}
                        src={`${item.url}`}
                        alt={item.name}
                        loading="lazy"
                        onClick={
                          ()=> {setSelectedImage(item.url)}
                        }
                    />
                  </ImageListItem>
              ))}
            </ImageList>
          </Grid>
          
        <Grid item sm={12} md={8}>
            <Grid container spacing={3} className={classes.detailItem}>
              <Grid item xs={12} sm={12} className={classes.priceRow}>
                {isClientOrGreater && (
                <div>
                  <Tooltip
                                title="Партнерская цена"
                                arrow
                                placement="top"
                            >
                    <Typography variant="h6" style={{ fontWeight: 300 }}>Дроп-цена</Typography>
                    </Tooltip>
                      <Typography variant='h5'>{formatPrice(price_drop)} ₴</Typography>
                    </div>
                )}
              <div>
                <Tooltip
                                title="Рекомендованная розничная цена"
                                arrow
                                placement="top"
                            >
                  <Typography variant="h6" style={{ fontWeight: 300 }}>РРЦ</Typography>
                  </Tooltip>
                  <Typography variant='h5'>{formatPrice(price)} ₴</Typography>
              </div>
              {isClientOrGreater && (
              <div>
                <Typography variant="h6" style={{ fontWeight: 300 }}>Заработок</Typography>
                <Typography variant='h5' style={{ color: '#33C0A7' }}>
                  {price && price_drop &&
                    formatPrice(price) - formatPrice(price_drop)
                  } ₴
                </Typography>
                </div>
                )}
              </Grid>              
          </Grid>
          {isClientOrGreater && (
                  <Grid item sm={8} xs={12} className={classes.detailItem}>
                    <p className={classes.inStock}>
                      {status === 1 && <><CheckCircle fontSize='medium' color='secondary' /> В наличии</>}
                      {status === 2 && <><Error fontSize='medium' className='warning' /> Заканчивается</>}
                      {status === 3 && <><Cancel fontSize='medium' color='error' /> Нет в наличии</>}
                    </p>
            </Grid>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} >
              <Grid container spacing={1} >
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Артикул:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">{vendor_code}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Бренд:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">
                    {vendor_name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Категория:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Chip
                    component={Link}
                    label={category_name}
                    color='primary'
                    variant='outlined'
                    to={`${productsPath}?category_id=${category_id}`}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          
          <Grid container spacing={3}>   
            <Grid item sm={12} xs={12}>
                <div className={classes.countContainer}>
                  <ToOrderInput
                      buttonColor='primary'
                      buttonContent='В заказ'
                      onAdd={(count) => {
                        addProduct({ count, product: { ...data} })
                      }}
                  />
                </div>
              </Grid>
            </Grid>
        </Grid>
        <Grid item sm={12} md={12} className={classes.detailItem}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Описание" {...a11yProps(0)} />
                    <Tab label="Параметры" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <Typography variant='subtitle2' style={{ fontWeight: 300 }}>{description}</Typography>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  {
                    params.map(param => (
                      <div key={param.name}>
                        <Grid container spacing={2} >
                          <Grid item xs={12} sm={6} >
                            <Grid container spacing={1} >
                              <Grid item xs={6}>
                                <Typography variant="subtitle2" style={{ fontWeight: 300 }}>{param.name}:</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="subtitle2" style={{ fontWeight: 300 }}>{param.value}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    ))
                  }
                </CustomTabPanel>
              </Box>
        </Grid>
        </Grid>
      </Paper>
  )
}

export default ProductView


ProductView.propTypes = {
  classes: PropTypes.object.isRequired,
  getData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  product: PropTypes.object,
  location: PropTypes.object.isRequired,
}

ProductView.defaultProps = {
  product: {},
}
